import React, { useEffect, useState, useRef } from "react";

//style
//import "./customSwitch.scss";

function CustomSwitch({
  startingValue,
  offFunction = null,
  onFunction = null,
  relatedSwitch,
  disabled = false,
}) {
  const [active, setActive] = useState(startingValue);
  const state = useRef(active);

  const handleChange = () => {
    setActive(!active);
  };

  //   if (active !== relatedSwitch && !relatedSwitch && !disabled) {
  //     handleChange();
  //   }

  useEffect(() => {
    if (active !== state.current) {
      active && onFunction && onFunction();
      !active && offFunction && offFunction();
      state.current = active;
    }
    state.current = active;
  }, [active]);

  return (
    <div className="relative my-4" onClick={handleChange}>
      <input
        type="checkbox"
        id="toggleB"
        className="sr-only"
        checked={active}
        // onChange={handleChange}
      />
      <div
        //w-14 h-8
        className={`${
          active ? "bg-primary" : "bg-gray-600"
        } block  w-10 h-6 rounded-full `}
      />
      <div
        //w-6 h-6
        className={`dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition ${
          active && "transform translate-x-full "
        }`}
      ></div>
    </div>
  );
}

export default CustomSwitch;

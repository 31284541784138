// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  updateEmail,
  signOut,
  confirmPasswordReset,
  updatePassword,
} from "firebase/auth";

import {
  getFirestore,
  query,
  getDocs,
  setDoc,
  getDoc,
  collection,
  where,
  addDoc,
  doc,
} from "firebase/firestore";

import { getFunctions } from "firebase/functions";

import { getStorage } from "firebase/storage";
import { DEFAULT_PROPIC } from "../../imports/constants";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app, "eu-central3");

const googleProvider = new GoogleAuthProvider();

const registerWithEmailAndPassword = async (userData, email, password) => {
  return createUserWithEmailAndPassword(auth, email, password)
    .then(async userCredential => {
      await setDoc(doc(db, "users", userCredential.user.uid), {
        ...userData,
        uid: userCredential.user.uid,
        email,
        propic: DEFAULT_PROPIC,
        authProvider: "local",
        createdAt: Date.now(),
      });

      sendEmailVerification(userCredential.user)
        .then(() => console.log("Verification email sent!"))
        .catch(err => {
          console.error(err);
        });
      return {
        status: 200,
        error: null,
        data: {
          ...userData,
          uid: userCredential.user.uid,
          email,
          authProvider: "local",
          emailVerified: !userCredential.user.emailVerified,
        },
      };
    })
    .catch(error => {
      console.log(error);
      return { status: 400, error };
    });
};

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    const { user } = await signInWithEmailAndPassword(auth, email, password);

    const docRef = doc(db, "users", user.uid);
    const docSnap = await getDoc(docRef);
    const userData = docSnap.data();

    return { error: null, userData, emailVerified: user.emailVerified };
  } catch (error) {
    console.log(error.code);
    return { error: error.code, status: 400 };
  }
};
const changeEmail = async email => {
  await updateEmail(auth.currentUser, email);
};
const sendVerificationEmail = async () => {
  await sendEmailVerification(auth.currentUser);
};

const changePassword = async password => {
  console.log(password);
  await updatePassword(auth.currentUser, password);
};

const sendPasswordReset = async email => {
  await sendPasswordResetEmail(auth, email);
};

const setNewPassword = async (newPassword, code) => {
  await confirmPasswordReset(auth, code, newPassword);
};

const getDocuments = async (collectionName, queryEl) => {
  const q = query(
    collection(db, collectionName),
    where(queryEl[0], queryEl[1], queryEl[2]),
  );

  const querySnapshot = await getDocs(q);
  return Promise.all(querySnapshot.map(doc => doc.data()));
};

const getAllDocuments = async collectionName => {
  const querySnapshot = await getDocs(collection(db, collectionName));

  return Promise.all(querySnapshot.docs.map(doc => doc.data()));
};

const logout = () => {
  try {
    signOut(auth);
  } catch (err) {
    console.log("logout error! ", err);
  }
};

export {
  auth,
  db,
  storage,
  functions,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  changeEmail,
  sendVerificationEmail,
  changePassword,
  sendPasswordReset,
  setNewPassword,
  getDocuments,
  getAllDocuments,
  logout,
};

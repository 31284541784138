//react
import React from "react";

//components
import { StyledButton } from "../index";

//style
// import "./fileInput.scss";

const ProPicInput = ({
  styleType = "primary",
  onChange = null,
  isDisabled = false,
  children = "no children",
  leftIcon = null,
  rightIcon = null,
  className = "",
  acceptingFiles = "application/pdf, .jpg, .png, .json",
}) => {
  const handleClick = () => {
    document.getElementById("propic").click();
  };

  const handleSubmit = e => {
    e.preventDefault();

    // const formData = new FormData(document.getElementById(id));

    onChange();
  };

  return (
    <div
      id="proPic"
      className="relative flex items-center justify-center w-full"
    >
      <div
        //input-container
        className="hidden"
      >
        <input
          id="propic"
          type="file"
          onInput={e => {
            onChange(e.target.files[0]);
          }}
          accept={acceptingFiles}
        />
      </div>
      <StyledButton
        styleType={styleType}
        onClick={handleClick}
        isDisabled={isDisabled}
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        className={className}
      >
        {children}
      </StyledButton>
    </div>
  );
};

export default ProPicInput;

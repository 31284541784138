//react
import React, { useState, useEffect } from "react";

import i18n from "../../imports/i18n";

//test
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

import { ipfsNode } from "../../enhancers/createIpfsEnhancer";

import { getIPFSFile } from "../../imports/utils";

function DocumentImage({
  src,
  base64,
  ipfsAddress,
  type,
  privateKey = false,
  isOwner,
}) {
  const [img, setImage] = useState(null);

  useEffect(() => {
    async function fetchFromIPFS() {
      await getIPFSFile(ipfsAddress, base =>
        setImage(
          `data:${
            type.includes("pdf") ? "image/svg+xml" : type
          };base64,${base}`,
        ),
      );
    }

    if (src && type !== "application/pdf") {
      setImage(src);
    } else if (!base64) {
      if (!img && ipfsAddress) {
        fetchFromIPFS();
      }
    }
  }, [ipfsNode]);

  return (
    <div id="documentImage" style={{ width: "95%" }} className="max-w-450">
      {!(img || base64) && type === "application/pdf" ? (
        <div className="image">
          {src ? (
            <img
              className="flex items-center justify-center object-contain w-full h-48 text-2xl font-semibold text-center rounded-20 bg-grey text-secondary"
              src={src}
              alt={i18n.t("loading")}
            />
          ) : (
            <div className="flex items-center justify-center object-contain w-full h-48 text-2xl font-semibold text-center rounded-20 bg-grey text-secondary">
              <PictureAsPdfIcon fontSize={"large"} />
            </div>
          )}
        </div>
      ) : img || base64 ? (
        <img
          className="flex items-center justify-center object-contain w-full h-48 text-2xl font-semibold text-center rounded-20 bg-grey text-secondary"
          src={img || base64}
          alt={i18n.t("loading")}
        />
      ) : (
        <div className="flex items-center justify-center object-contain w-full h-48 text-2xl font-semibold text-center rounded-20 bg-grey text-secondary">
          {i18n.t("no_image")}
        </div>
      )}
    </div>
  );
}

export default DocumentImage;

import React from "react";

//material
import Checkbox from "@material-ui/core/Checkbox";

//components
import { Icon, Icons } from "../index";

//style
//import "./customCheckbox.scss";
import { colors } from "../../imports/constants";

export function CustomCheckbox({
  label = null,
  checked = false,
  onClick = null,
  disabled = false,
}) {
  return (
    <div>
      <div
        // className={`checkboxRowContainer${
        //     checked ? "Checked" : "Unchecked"
        // }`}
        className={`w-full text-xl flex items-center justify-center font-regular `}
      >
        <Checkbox
          disabled={disabled}
          size="small"
          color="primary"
          style={{ padding: 5, marginRight: "10px" }}
          checked={checked}
          onClick={onClick}
          icon={
            <div
              //checkbox-unchecked-box
              className={`h-8 w-8 rounded flex justify-center items-center box-border ${
                disabled ? "bg-disabledGrey" : "bg-blackGradient"
              }`}
            ></div>
          }
          checkedIcon={
            <div
              //checkbox-checked-box
              className={`h-8 w-8 rounded flex justify-center items-center box-border bg-primaryGradient`}
              style={{
                boxShadow: "0px 14px 10px -12px #ef6255",
              }}
            >
              <Icon
                name={Icons.CHECK}
                fill={colors.secondary}
                style={{
                  width: 20,
                  height: 20,
                }}
              />
            </div>
          }
        />
        {label}
      </div>
    </div>
  );
}

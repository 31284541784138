import React, { useState } from "react";

//material
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

//components
import { Icon, Icons } from "../index";

//style
//import "./customSelect.scss";
import { colors } from "../../imports/constants";

function CustomSelect(
  {
    label = null,
    value = "",
    onChange = null,
    selectable = [], //array made with objects with label and value
    id, //required
  },
  ...props
) {
  const [opened, toggleSelect] = useState(false);

  return (
    <div className={`w-full max-w-450 rounded-10`}>
      <FormControl>
        <InputLabel
          id={`select-label${id}`}
          //select-label
          // className={`pl-2 text-base z-1 font-medium font-barlow "text-grey"
          //           `}
          className={`pl-2 text-base z-1 font-medium font-futura "text-grey"
                    `}
          onClick={() => toggleSelect(!opened)}
        >
          {label}
        </InputLabel>
        <Select
          labelId={`select-label${id}`}
          id={`select${id}`}
          value={value}
          open={opened}
          onClose={() => toggleSelect(!opened)}
          onOpen={() => toggleSelect(!opened)}
          onChange={e => {
            onChange && onChange(e.target.value);
            toggleSelect(!opened);
          }}
          IconComponent={() => (
            <Icon
              fill={colors["black"]}
              name={Icons.ARROW}
              style={{
                width: 15,
                height: 10,
                transform: "rotate(270deg)",
              }}
            />
          )}
        >
          <MenuItem
            className={`text-base font-medium bg-lightGrey text-black
                        `}
            value=""
          />
          {selectable.map((item, i) => (
            <MenuItem
              key={i}
              className={`text-base font-medium bg-lightGrey text-black
                            `}
              value={item.value}
            >
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default CustomSelect;

//react
import React from "react";
import { useHistory } from "react-router-dom";

//components
import { CircleIconButton, Icon, Icons } from "../index";

//style
// import "./topBar.scss";
import { colors } from "../../imports/constants.js";

function TopBar({
  title = "no title",
  goBack = false,
  onClickLeft = null,
  onClickRight = null,
  rightIcon = null,
  logo = false,
  admin = false,
}) {
  const history = useHistory();

  const handleClick = e => {
    if (e) {
      goBack ? history.goBack() : onClickLeft && onClickLeft();
    } else {
      onClickRight && onClickRight();
    }
  };

  return (
    <div className={`w-full fixed top-0 z-10 max-w-600 h-topBarHeight`}>
      {/* {logo && (
        <div className="flex flex-col items-center justify-center w-full mt-3">
          <Icon
            name={Icons.FOREVER_BAMBU_LOGO}
            style={{
              width: 200,
              height: 200,
              // marginRight: 25,
            }}
          />
        </div>
      )} */}
      <div
        //topBarContainer
        className={`w-full pt-5 pb-5 box-border shadow-none flex justify-between items-end relative h-topBarHeight pl-defaultMargin pr-defaultMargin bg-secondary`}
      >
        <CircleIconButton
          size="small"
          onClick={() => onClickLeft && handleClick(true)}
          icon={
            <Icon
              name={Icons[goBack ? "ARROW" : "HAMBURGERMENU"]}
              fill={colors.primary}
              style={{
                width: 25,
                height: 20,
              }}
            />
          }
          disabled={!onClickLeft}
          styleType="secondary"
        />
        <div
          //topBarTitle
          className={`text-3xl whitespace-no-wrap font-bold text-primary ${
            !admin && "mx-auto" //needed to center when not cross icon
          }`}
          style={{ wordBreak: "keep-all" }}
        >
          {title}
        </div>

        {admin ? (
          <CircleIconButton
            size="small"
            onClick={() => onClickRight && handleClick(false)}
            icon={
              rightIcon ? (
                <Icon
                  name={Icons[rightIcon]}
                  fill={colors.secondary}
                  style={{
                    width: 20,
                    height: 20,
                  }}
                />
              ) : (
                <div
                  //empty-box
                  className="w-5 h-5"
                ></div>
              )
            }
            disabled={!onClickRight}
            styleType={rightIcon ? "primary" : "secondary"}
          />
        ) : (
          <div
            //empty-box
            className="w-6 h-6"
          ></div>
        )}
      </div>
    </div>
  );
}

export default TopBar;

//react
import React from "react";

//material
import Drawer from "@material-ui/core/Drawer";

const BottomDrawer = ({ open, toggleDrawer, children }) => {
  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={toggleDrawer}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
        },
      }}
    >
      {children}
    </Drawer>
  );
};

export default BottomDrawer;

import { ethers } from "ethers";
import { toast } from "react-toastify";
import i18n from "./i18n";
import packageJson from "../../package.json";

const currentEnv = process.env.REACT_APP_ENV;
let provider = null;

export const abiCoder = ethers.utils.defaultAbiCoder;
// export const polygonProvider = new ethers.providers.JsonRpcProvider(
//    process.env[`REACT_APP_RPC_PROVIDER`],
// );

export function getProvider() {
    try {
        if (!provider) {
            provider = new ethers.providers.JsonRpcProvider(
                process.env[`REACT_APP_RPC_PROVIDER`],
            );
        }

        return provider;
    } catch (e) {
        console.log(e);
        toast.error(i18n.t("errors.unable_to_connect"), {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        });
        return null;
    }
}

console.log(
    `%cVersion:%c${packageJson.version}`,
    "color:white; background: #34495e; border-radius: 2px 0 0 2px; padding: 0.1rem 0.5rem; border-right: none;",
    "color:white; background: #3498db; border-radius: 0 2px 2px 0; padding: 0.1rem 0.5rem; border-left: none;",
);
console.log(
    `%cEnv:%c${currentEnv}`,
    "color:white; background: #34495e; border-radius: 2px 0 0 2px; padding: 0.1rem 0.5rem; border-right: none;",
    "color:white; background: #3498db; border-radius: 0 2px 2px 0; padding: 0.1rem 0.5rem; border-left: none;",
);
console.log(
    `%cNetwork:%c${process.env[`REACT_APP_RPC_PROVIDER`]}`,
    "color:white; background: #34495e; border-radius: 2px 0 0 2px; padding: 0.1rem 0.5rem; border-right: none;",
    "color:white; background: #3498db; border-radius: 0 2px 2px 0; padding: 0.1rem 0.5rem; border-left: none;",
);

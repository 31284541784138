import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export default function Redirects() {
  const history = useHistory();

  useEffect(() => {
    if (history.location.search) {
      const action = history.location.search.match(/\?mode=(.*?)&/)[1];
      const oobCode = history.location.search.match(/&oobCode=(.*?)&/)[1];
      history.push({ pathname: `/${action}`, state: { oobCode } });
    }
  }, []);

  return null;
}

/* Splash Screen page: after every transaction on blockchain, 
this splashscreen let user know if it was successful or not */

//react
import React from "react";

//components
import { StyledButton, Icons, Icon } from "../../index";

//animations
import {
  Failure,
  Loading,
  Success,
  BambuLoader,
} from "../../../imports/animations/index";
import Lottie from "react-lottie-player";
// import LoadingBambu from "./bambu.gif";

const SplashScreen = ({
  type = "success",
  text,
  message,
  buttonText,
  buttonClick,
}) => {
  const isSuccess = type === "success";
  const isFailure = type === "failure";

  return (
    <div className={`absolute top-0 left-0 z-10`}>
      <div
        className={`overflow-hidden flex flex-col justify-center items-center relative text-center text-secondary max-w-600 w-screen h-screen
         ${isSuccess ? " bg-primary" : isFailure ? "bg-red" : "bg-primary"}`}
      >
        {type === "pendingSignup" ? (
          <Icon
            name={Icons.BAMBU}
            style={{
              width: "280px",
              height: "280px",
              position: "absolute",
              top: "10%",
            }}
          />
        ) : (
          <div
            //TODO reason: no sizes available
            className="absolute rounded-full right-1/3"
            style={{
              width: "500px",
              height: "500px",
              top: "-13%",
              background: "rgba(255, 255, 255, 0.2)",
            }}
          ></div>
        )}
        {/* <div
          //TODO reason: no sizes available
          className="absolute rounded-full left-1/4"
          style={{
            width: "580px",
            height: "580px",
            top: "-13%",
            background: "rgba(255, 255, 255, 0.2)",
          }}
        ></div> */}

        <div className={`absolute top-1/4`}>
          {type === "success" ? (
            <div
              //animation-div
              className="w-40 h-40"
            >
              <Lottie loop={false} play animationData={Success} />
            </div>
          ) : type === "failure" ? (
            <div //animation-div
              className="w-40 h-40"
            >
              <Lottie loop={false} play animationData={Failure} />
            </div>
          ) : (
            type !== "pendingSignup" && (
              <div //animation-div
                className="w-40 h-40"
              >
                {/* <img
                  src={LoadingBambu}
                  alt="loading"
                /> */}
                <Lottie loop={true} play animationData={Loading} />
              </div>
            )
          )}
        </div>
        <div
          //text
          className="absolute text-4xl font-bold top-2/4"
          style={{ lineHeight: "60px" }}
        >
          <p style={{ margin: "0" }}>{text}</p>
        </div>
        <div className="absolute text-lg font-normal top-3/4">
          <p>{message}</p>
        </div>
        <div
          className="absolute w-11/12 text-3xl font-normal "
          style={{ bottom: "7%" }}
        >
          {buttonText &&
            buttonClick &&
            (type === "success" || type === "failure") && (
              <div
                className={
                  "font-medium text-3xl w-full flex justify-center items-center"
                }
              >
                <StyledButton
                  onClick={buttonClick}
                  className={`w-4/5 m-auto h-fit pt-2 pb-2.5 pl-2 pr-2.5 ${
                    isSuccess ? "" : "text-red border-red"
                  }`}
                  styleType="secondary"
                >
                  {buttonText}
                </StyledButton>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default SplashScreen;

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { createContext, useEffect, useState } from "react";
import {
  db,
  storage,
  logInWithEmailAndPassword,
  logout,
  registerWithEmailAndPassword,
  changeEmail,
  changePassword,
  sendPasswordReset,
  setNewPassword,
  sendVerificationEmail,
} from "./firebase";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import i18n from "../../imports/i18n";
import { isEqual, omit } from "lodash";
import { sdk, usePrevious } from "../../imports/utils";
import { TOAST_CONFIG } from "../../imports/constants";

export const AuthContext = createContext();

const DEFAULT_USER = {
  logged: false,
  needRecover: false,
};

export const AuthProvider = ({ children }) => {
  const [userAuth, setUserAuth] = useState();
  const [error, setError] = useState(null);
  const [user, setUserData] = useState(DEFAULT_USER);
  const [isLoading, setIsLoading] = useState(true);

  const prevAuth = usePrevious(userAuth);

  const setUser = data => {
    setUserData(data);
    // console.log(data);
    // console.log(user);
    localStorage.setItem("user", JSON.stringify(omit(data, ["wallet"])));
  };

  const getUserData = async uid => {
    setIsLoading(true);

    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);

    const privateKey = localStorage.getItem("privateKey");

    setUser({
      ...user,
      ...omit(docSnap.data(), ["wallet"]),
      emailVerified: !!userAuth?.emailVerified,
      wallet: privateKey ? new ethers.Wallet(privateKey) : user?.wallet,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    const auth = getAuth();
    onAuthStateChanged(
      auth,
      data => {
        if (!isEqual(prevAuth, data)) {
          setUserAuth(data);
        }
      },
      setError,
    );
    setIsLoading(false);
  }, []);

  // console.log("userAuth: ", userAuth);
  // console.log("user: ", user);

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("user"));
    if (savedData && !isEqual(savedData, omit(user, ["wallet"]))) {
      if (userAuth?.uid) {
        getUserData(userAuth.uid);
      }

      const privateKey = localStorage.getItem("privateKey");
      if (privateKey) sdk.setPrivateKey(privateKey);
      setUser({
        ...savedData,
        wallet: privateKey ? new ethers.Wallet(privateKey) : user?.wallet,
      });
    } else {
    }
  }, [userAuth]);

  const registerUser = async userData => {
    setIsLoading(true);
    let wallet = null;
    if (userData.importWallet && userData.mnemonic) {
      try {
        if (/^0x[a-fA-F0-9]{64}$/.test(userData.mnemonic)) {
          wallet = new ethers.Wallet(userData.mnemonic);
        } else {
          wallet = ethers.Wallet.fromMnemonic(userData.mnemonic);
        }
        sdk.setPrivateKey(wallet.privateKey);
        localStorage.setItem("privateKey", wallet.privateKey);
      } catch (err) {
        toast.error(i18n.t("errors.invalid_mnemonic_privatekey"), TOAST_CONFIG);
        setIsLoading(false);
      }
    } else {
      wallet = ethers.Wallet.createRandom();
      localStorage.setItem("privateKey", wallet.privateKey);
    }

    userData.wallet = await wallet.encrypt(userData.password);
    userData.backupWallet = await wallet.encrypt(userData.secondaryPassword);
    userData.address = wallet.address.toLocaleLowerCase();

    console.log("Registering user...");

    const { status, data, error } = await registerWithEmailAndPassword(
      omit(userData, [
        "password",
        "repeatPassword",
        "secondaryPassword",
        "importWallet",
        "mnemonic",
      ]),
      userData.email,
      userData.password,
    );

    if (status === 200) {
      setUserAuth(data);
      setUser({
        ...omit(userData, [
          "password",
          "repeatPassword",
          "secondaryPassword",
          "importWallet",
          "mnemonic",
        ]),
        wallet,
        logged: false,
        emailVerified: data.emailVerified,
      });
      setIsLoading(false);
    }
  };

  const loginUser = async (email, password) => {
    let needRecover = false;
    const { error, userData, emailVerified } = await logInWithEmailAndPassword(
      email,
      password,
    );

    if (!error && !emailVerified) {
      toast.error(i18n.t(`errors.need_to_verify_email`), TOAST_CONFIG);
      await sendVerificationEmail();
      return;
    }

    if (!error) {
      setIsLoading(true);
      let wallet = null;
      try {
        wallet = await ethers.Wallet.fromEncryptedJson(
          userData.wallet,
          password,
        );
        // console.log(wallet);
        localStorage.setItem("privateKey", wallet.privateKey);
        sdk.setPrivateKey(wallet.privateKey);
      } catch (error) {
        wallet = userData.wallet;
        needRecover = true;
      }
      // if (user?.needRecover) {
      //   wallet = doc.wallet;
      // } else {
      //   wallet = await ethers.Wallet.fromEncryptedJson(doc.wallet, password);

      //   localStorage.setItem("privateKey", wallet.privateKey);
      // }

      // console.log("WALLET ==>", wallet.privateKey);

      // sdk.setPrivateKey(wallet.privateKey);

      setUser({
        ...user,
        ...userData,
        logged: true,
        wallet,
        needRecover,
      });
      setIsLoading(false);
    } else {
      toast.error(i18n.t(`errors.${error}`), TOAST_CONFIG);
      return;
      // setUser({ ...user, ...userData });
    }
  };

  const updateEmail = async email => {
    try {
      await changeEmail(email);
      await sendVerificationEmail();
      // setUser({
      //   ...user,
      //   email: email,
      // });
      await uploadUserData({ email: email });
      // signOut();
      // toast.success(i18n.t("email updated"), TOAST_CONFIG);
    } catch (err) {
      toast.error(i18n.t(err), TOAST_CONFIG);
    }
  };

  const resetPassword = async email => {
    try {
      await sendPasswordReset(email);
      //toast.success(i18n.t("reset_password.request_sent"), TOAST_CONFIG);
      toast.success(i18n.t("signup.email_alert"), TOAST_CONFIG);
    } catch (err) {
      toast.error(i18n.t("reset_password.sending_request_error"), TOAST_CONFIG);
    }
    // setUser({ ...user, needrecover: true });
  };

  const sendNewPassword = async (newPassword, code) => {
    // console.log(newPassword);
    try {
      await setNewPassword(newPassword, code);

      setUser({ ...user, needRecover: true });
      toast.success(i18n.t("reset_password.password_changed"), TOAST_CONFIG);
      return true;
    } catch (err) {
      toast.error(i18n.t("reset_password.sending_request_error"), TOAST_CONFIG);
      return false;
    }
  };

  const updatePassword = async password => {
    try {
      await changePassword(password);
      // setUser({ ...user, needRecover: true });
      // signOut();
      toast.success(i18n.t("reset_password.password_changed"), TOAST_CONFIG);
    } catch (error) {
      console.log(error);
      toast.error(i18n.t("reset_password.cannot_update"), TOAST_CONFIG);
    }
  };

  const recoverWallet = async ({ primaryPassword, secondaryPassword }) => {
    console.log("Recover wallet procedure...");

    const primaryWallet = await ethers.Wallet.fromEncryptedJson(
      user.backupWallet,
      secondaryPassword,
    );

    const primaryWalletEnc = await primaryWallet.encrypt(primaryPassword);
    await updateDoc(doc(db, "users", userAuth.uid), {
      wallet: primaryWalletEnc,
    });
    setUser({ ...user, wallet: primaryWallet, needRecover: false });
  };

  const signOut = async () => {
    setUserAuth(null);
    // localStorage.clear();
    // localStorage.removeItem("privateKey");
    setUser(DEFAULT_USER);

    logout();
  };

  const uploadProPic = async file => {
    // console.log(file);
    const imagesRef = ref(storage, `/images/${userAuth.uid}`);

    uploadBytes(imagesRef, file)
      .then(snapshot => {
        getDownloadURL(imagesRef).then(url => {
          uploadUserData({ propic: url });
          // setUser({
          //   ...user,
          //   propic: url,
          // });
          // toast.success(i18n.t("messages.done"), TOAST_CONFIG);
        });
      })
      .catch(error => console.log("error ", error));
  };

  const uploadUserData = async data => {
    // console.log(data);
    try {
      await updateDoc(doc(db, "users", userAuth.uid), data);
      setUser({
        ...user,
        ...data,
      });
      toast.success(i18n.t("messages.pro_data_updated"), TOAST_CONFIG);
    } catch (error) {
      console.log(error);
      toast.error(i18n.t("errors.unable_to_update"), TOAST_CONFIG);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        userAuth,
        user,
        registerUser,
        loginUser,
        updateEmail,
        resetPassword,
        sendNewPassword,
        recoverWallet,
        signOut,
        isLoading,
        error,
        uploadProPic,
        uploadUserData,
        updatePassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

//react
import React from "react";

//material
import Modal from "@material-ui/core/Modal";

export function CustomModal({
  content = null,
  opened = false,
  onBackdropClick = null,
  style = {},
}) {
  return (
    <Modal open={opened}>
      <div
        //modalContainer
        className="box-border flex flex-col items-center justify-center w-full h-screen outline-none pt-defaultMargin pb-defaultMargin"
        onClick={onBackdropClick}
      >
        <div
          className={`bg-secondary pt-haldMargin pb-halfMargin pl-defaultMargin pr-defaultMargin`}
          onClick={e => e.stopPropagation()}
          style={{
            ...style,
            borderRadius: "15px",
            width: "400px",
          }}
        >
          {content}
        </div>
      </div>
    </Modal>
  );
}

//---Documentation---
//open status has to be managed on father component.
//onBackdropClick is triggered when the background is pressed, use it to manage open status.

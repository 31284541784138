/* This page shows details of specified nft */

//react
import React, { useEffect, useReducer, useContext } from "react";

//components
import {
  CustomInput,
  CustomLabel,
  StyledButton,
  CircleIconButton,
  BottomDrawer,
  DocumentImage,
  SplashScreen,
  NotarizeDocument,
} from "../../components";
import { Icons, Icon } from "../../components/icons/icons";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";

//Lottie
import { SecondaryLoading } from "../../imports/animations/index";
import Lottie from "react-lottie-player";

//style
import {
  colors,
  CONTRACTS,
  IPFS_GATEWAY,
  TOAST_CONFIG,
} from "../../imports/constants";
// import "./nftDetail.scss";

//i18n
import i18n from "../../imports/i18n";

//carousel
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { useQuery } from "react-query";
import { getTokenData, getUserByAddr, transferNFTCall } from "./queries";

import SwiperCore, { Pagination } from "swiper/core";
import {
  tokenIsOwnedByAddress,
  truncStringPortion,
  usePrevious,
  downloadFromIPFS,
  download,
} from "../../imports/utils";
import { toast } from "react-toastify";

//Firebase
import { useFirestoreDocument } from "@react-query-firebase/firestore";
import { doc } from "firebase/firestore";
import { db } from "../../redux-observables/firebase/firebase";

import { AuthContext } from "../../redux-observables/firebase/context";
import { PDFDownloadLink } from "@react-pdf/renderer";

const details = {
  name: "nft_detail.cell_name",
  description: "nft_detail.cell_description",
  latitude: "notarize.coordinates",
  longitude: "",
  area: "notarize.extension",
  carbonT: "notarize.total_co2",
  carbonY: "notarize.annually_co2",
  note: "nft_detail.cell_note",
};

// install Swiper modules
SwiperCore.use([Pagination]);

export default function NftDetail(props) {
  const { match } = props;

  const documentId = match.params.id;

  function reducer(state, action, data) {
    switch (action.type) {
      case "openModal":
      case "loader":
        return {
          ...state,
          [action.type]: action.payload || !state[action.type],
        };

      default:
        return { ...state, [action.type]: action.payload };
    }
  }
  const [state, dispatch] = useReducer(reducer, {
    openModal: false,
    selectedModal: null,
    addressReceiver: null,
    activeQuery: false,
    isOwned: false,
    currentToken: null,
    loader: false,
    selectedNFT: null,
    document: null,
    // isUnlocked: false,
  });

  const {
    openModal,
    selectedModal,
    addressReceiver,
    currentToken,
    loader,
    document,
  } = state;

  const { user } = useContext(AuthContext);
  const {
    data: product,
    isLoading,
    refetch: reloadDoc,
  } = useFirestoreDocument(["nfts", documentId], doc(db, "nfts", documentId));

  const {
    refetch: transferNFT,
    isLoading: transferLoading,
    error: transferError,
  } = useQuery(
    "transferNFT",
    async () => {
      dispatch({ type: "openModal", payload: false });

      await transferNFTCall(
        process.env[CONTRACTS.FOREVER_BAMBU.env],
        document.tokenId,
        addressReceiver,
        { nftId: document.id },
      );

      reloadDoc();
    },
    { enabled: false },
  );

  useEffect(() => {
    if (product) {
      const data = product.data();
      //console.log("data: ", data.owner);

      // const getCreator = async () => {
      //   console.log(data.owner);
      //   const creator =
      //     data.owner === user.address
      //       ? user.username
      //       : (await getUserByAddr(data.owner)) ||
      //         i18n.t("nft_detail.deleted_user");
      //   console.log(creator);

      //   dispatch({
      //     type: "document",
      //     payload: { ...data, creator: creator?.username },
      //   });
      // };
      // getCreator();
      dispatch({
        type: "document",
        payload: data,
      });
    }
  }, [product]);

  const prevCurrentToken = usePrevious(currentToken);
  const prevLoading = usePrevious(transferLoading);

  const downloadDocument = () => {
    toast.success(
      "Data la decentralizzazione dei dati, il download comparira' a breve",
      TOAST_CONFIG,
    );
    downloadFromIPFS(document.cellData.name, false, document.ipfsAddresses.zip);
  };

  const { isLoading: tokenDataLoading } = useQuery(
    "tokenData",
    async () => {
      if (document) {
        dispatch({
          type: "document",
          payload: {
            ...document,
            ownerUsername:
              document.owner === user.address
                ? user.username
                : await getTokenData(
                    document.tokenId,
                    process.env.REACT_APP_FOREVER_BAMBU_ADDRESS,
                  ),
          },
        });
      }
    },
    { enabled: !!document },
  );

  useEffect(() => {
    const checkTokenValues = async () => {
      if (
        document &&
        (currentToken || currentToken === 0) &&
        currentToken !== prevCurrentToken
      ) {
        dispatch({
          type: "isOwned",
          payload: await tokenIsOwnedByAddress(
            currentToken,
            document?.maticTx.to,
            user.wallet,
          ),
        });
      }
    };
    checkTokenValues();
  }, [currentToken, document, prevCurrentToken, user.wallet]);

  useEffect(() => {
    if (!transferLoading && prevLoading) {
      dispatch({ type: "loader", payload: true });
      setTimeout(
        () => {
          dispatch({ type: "loader", payload: false });
        },
        transferError ? 3000 : 6000,
      );
    }
  }, [transferLoading]);

  const modal = () => {
    let component;

    switch (selectedModal) {
      case "transferNftDrawer":
        component = (
          <div
            className={`text-center flex flex-col items-center w-full pt-2 pb-12 overflow-auto h-2/3 m-auto rounded-t-50 max-w-600 bg-white`}
          >
            <h1>{i18n.t("transfer_nft")}</h1>
            <h2>{document.cellData.name}</h2>
            <div className="flex items-center justify-between">
              <CustomLabel
                label={i18n.t("notarize.transfer_nft_number", {
                  tokenId: document.tokenId,
                })}
              />
            </div>
            <CustomInput
              onChange={e =>
                dispatch({
                  type: "addressReceiver",
                  payload: e,
                })
              }
              placeholder={i18n.t("nft_detail.input_placeholder")}
            />
            <StyledButton
              // isDisabled={
              //     counter < 1 ||
              //     addressReceiver === null ||
              //     addressReceiver === ""
              // }
              isDisabled={!addressReceiver}
              onClick={transferNFT}
            >
              {i18n.t("nft_detail.transfer")}
            </StyledButton>
            <StyledButton
              onClick={() => {
                dispatch({ type: "openModal" });
              }}
              styleType="secondary"
            >
              {i18n.t("nft_detail.cancel")}
            </StyledButton>
          </div>
        );
        break;

      default:
        break;
    }

    return (
      <BottomDrawer
        id="bottom-drawer"
        toggleDrawer={() => dispatch({ type: "openModal" })}
        open={!!openModal}
      >
        {/* {tokenDataLoading || tokenHistoryLoading ? (
          <Loader type={""} />
        ) : ( */}
        {component}
        {/* )} */}
      </BottomDrawer>
    );
  };

  //return transfering && (loading || loader) ? (
  return transferLoading || loader ? (
    <SplashScreen
      type={
        transferLoading
          ? "pending"
          : loader && !transferError
          ? "success"
          : "failure"
      }
      message={
        transferLoading
          ? i18n.t("messages.nft_transfer_pending")
          : loader && !transferError
          ? i18n.t("messages.nft_transfer_success")
          : i18n.t("messages.nft_transfer_failed")
      }
    />
  ) : isLoading || tokenDataLoading || !document ? (
    <div className={`w-full relative`}>
      <Lottie
        className="loading-spinner"
        play
        animationData={SecondaryLoading}
      />
    </div>
  ) : (
    <div className={`w-full relative`} style={{ height: "calc(100vh-80px" }}>
      {document && modal()}
      <div className="flex items-center justify-center w-full mb-8">
        <DocumentImage
          src={`${IPFS_GATEWAY}/${document?.ipfsAddresses.cover}`}
          ipfsAddress={document?.ipfsAddresses.cover}
          type={"image/svg+xml"}
        />
      </div>

      {Object.keys(details).map(function (key) {
        return (
          (key !== "note" || (user.role === "admin" && key === "note")) && (
            <div key={key} className="flex flex-col px-30">
              {details[key] && (
                <span className="mt-3 mb-1 font-semibold text-black">
                  {`${i18n.t(details[key])}`}
                </span>
              )}
              <span className="my-2 ml-5 font-normal text-black">
                {`${document.cellData[key]} ${
                  key === "area"
                    ? "mq"
                    : key === "carbonT" || key === "carbonY"
                    ? "t"
                    : ""
                }`}
              </span>
            </div>
          )
        );
      })}

      <div className="flex flex-col px-30">
        <span className="mt-3 mb-1 font-semibold text-black">
          {`${i18n.t("nft_detail.current_owner")}`}
        </span>

        <span className="my-2 ml-5 font-normal text-black">
          {document.ownerUsername}
        </span>

        <span className="mt-3 mb-1 font-semibold text-black">
          {`${i18n.t("notarize.ipfs")}`}
        </span>
        <div className="flex flex-row">
          <span className="my-2 ml-5 font-normal text-black">
            {truncStringPortion(
              document.ipfsAddresses.zip,
              12,
              document.ipfsAddresses.zip.length - 12,
            )}
          </span>

          <CircleIconButton
            onClick={() =>
              navigator.clipboard.writeText(document.ipfsAddresses.zip)
            }
            className="my-auto ml-auto mr-0 cursor-pointer"
            icon={
              <Icon
                name={Icons.COPY}
                fill={colors.primary}
                style={{
                  width: 15,
                  height: 15,
                }}
              />
            }
            styleType="secondary"
          />
        </div>

        {
          //if  admin and if nft has not already been transferred
          user.role === "admin" &&
          user.wallet.address.toLowerCase() === document.owner.toLowerCase() ? (
            <div className="flex flex-row">
              <span className="mt-3 mb-5 font-medium text-black">
                {`${i18n.t("contracts.download_document")}`}
              </span>
              <CircleIconButton
                onClick={() => {
                  downloadDocument();
                }}
                className="my-auto ml-auto mr-0 cursor-pointer"
                icon={
                  <Icon
                    name={Icons.DOWNLOAD}
                    fill={colors.primary}
                    style={{
                      width: 15,
                      height: 15,
                    }}
                  />
                }
                styleType="secondary"
              />
            </div>
          ) : (
            <StyledButton
              onClick={() => {
                // console.log("download button");
                downloadDocument();
              }}
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "10px",
              }}
              // isDisabled={!isOwned}
            >
              {i18n.t("contracts.download_document")}
            </StyledButton>
          )
        }
      </div>

      {user.role === "admin" &&
        user.wallet.address.toLowerCase() === document.owner &&
        !document.transfer.underTransfer && (
          <StyledButton
            onClick={() => {
              dispatch({
                type: "selectedModal",
                payload: "transferNftDrawer",
              });
              dispatch({ type: "openModal", payload: document });
              toast.warning(i18n.t("messages.transfer_warning"), {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
              });
            }}
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "10px",
            }}
            // isDisabled={!isOwned}
          >
            {i18n.t("nft_detail.transfer")}
          </StyledButton>
        )}

      <PDFDownloadLink
        fileName={document?.cellData.name}
        document={<NotarizeDocument notarization={document} />}
        style={{
          width: "100%",
          maxWidth: "350px",
          textDecoration: "none",
          // marginTop: 30,
        }}
      >
        <StyledButton
          styleType="secondary"
          // onClick={() => {
          //   console.log("download report button");
          // }}
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "10px",
          }}
          // isDisabled={!isOwned}
        >
          {i18n.t("contracts.download_report")}
        </StyledButton>
      </PDFDownloadLink>
    </div>
  );
}

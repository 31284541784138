/* IMPORTING PAGES */
import {
  Home,
  Onboarding,
  Notarize,
  UserManager,
  Profile,
  Nfts,
  Login,
  Signup,
  PecVerification,
  PhoneVerification,
  NftDetail,
  UserDetails,
  VerifyDocument,
  CardManage,
  Buying,
  SuccessPage,
  ResetPassword,
  EasterEgg,
  EmailVerification,
  RecoverEmail,
  Redirects,
  Faq,
} from "../pages/index";

//i18n
import i18n from "./i18n";

//formik
import * as Yup from "yup";

export const currentEnv = process.env.REACT_APP_ENV;

export const routes = [
  {
    path: "/",
    component: Home,
    hasTopBar: true,
    topBarIcon: "ADD",
    topBarLabel: i18n.t("topBar.home"),
    topBarFunction: "/notarize",
    hasDrawer: true,
    inDrawer: true,
    drawerIcon: "NFT",
    drawerLabel: i18n.t("drawer.home"),
    logged: true,
    admin: false,
    props: [],
    redirect: "/login",
    disabled: false,
  },
  {
    path: "/userManager",
    component: UserManager,
    hasTopBar: true,
    topBarLabel: i18n.t("topBar.user_manager"),
    hasDrawer: true,
    inDrawer: true,
    drawerIcon: "SOCIALMEDIA",
    drawerLabel: i18n.t("drawer.user_manager"),
    logged: true,
    admin: true,
    role: "admin",
    props: [],
    redirect: "/login",
    disabled: false,
  },
  {
    path: "/profile",
    component: Profile,
    hasTopBar: true,
    topBarLabel: i18n.t("topBar.profile"),
    hasDrawer: true,
    inDrawer: true,
    drawerIcon: "PROFILE",
    drawerLabel: i18n.t("drawer.profile"),
    logged: true,
    admin: false,
    props: [],
    redirect: "/login",
    disabled: false,
  },
  // {
  //   path: "/feedback",
  //   component: Feedback,
  //   hasTopBar: true,
  //   topBarLabel: i18n.t("topBar.feedback"),
  //   hasDrawer: true,
  //   inDrawer: true,
  //   drawerIcon: "FEEDBACK",
  //   drawerLabel: i18n.t("drawer.feedback"),
  //   logged: true,
  //   props: [],
  //   redirect: "/login",
  //   disabled: false,
  // },

  //out of drawer

  // {
  //   path: "/buying",
  //   component: Buying,
  //   goBack: true,
  //   hasTopBar: true,
  //   topBarLabel: i18n.t("topBar.buying"),
  //   hasDrawer: true,
  //   logged: true,
  //   props: [],
  // },
  // {
  //   path: "/card-manage",
  //   component: CardManage,
  //   goBack: true,
  //   hasTopBar: true,
  //   topBarLabel: i18n.t("topBar.add_card"),
  //   hasDrawer: true,
  //   logged: true,
  //   props: [],
  // },
  // {
  //   path: "/verification-pec",
  //   component: PecVerification,
  //   goBack: true,
  //   hasTopBar: true,
  //   topBarLabel: i18n.t("topBar.verify_pec"),
  //   hasDrawer: true,
  //   logged: true,
  //   props: [],
  // },
  // {
  //   path: "/verification-phone",
  //   component: PhoneVerification,
  //   goBack: true,
  //   hasTopBar: true,
  //   topBarLabel: i18n.t("topBar.verify_phone"),
  //   hasDrawer: true,
  //   logged: true,
  //   props: [],
  // },
  {
    path: "/nft-detail/:id",
    component: NftDetail,
    goBack: true,
    hasTopBar: true,
    topBarLabel: i18n.t("topBar.nft"),
    hasDrawer: false,
    logged: true,
    props: [],
  },
  {
    path: "/user-details/:id",
    component: UserDetails,
    goBack: true,
    hasTopBar: true,
    topBarLabel: i18n.t("topBar.user_details"),
    hasDrawer: false,
    logged: true,
    role: "admin",
    redirect: "/login",
    props: [],
  },
  // {
  //   path: "/verifyDoc",
  //   component: VerifyDocument,
  //   hasTopBar: true,
  //   topBarLabel: i18n.t("topBar.verify_doc"),
  //   hasDrawer: false,
  //   goBack: true,
  //   logged: true,
  //   props: [],
  // },
  {
    path: "/test",
    component: EasterEgg,
    hasTopBar: true,
    topBarLabel: i18n.t("topBar.easter_egg"),
    hasDrawer: false,
    goBack: true,
    logged: true,
    props: [],
    redirect: "/test",
  },
  {
    path: "/notarize",
    component: Notarize,
    logged: true,
    hasTopBar: false,
    topBarLabel: i18n.t("topBar.notarize"),
    hasDrawer: false,
    goBack: true,
    role: "admin",
    props: [],
    redirect: "/login",
  },
  {
    path: "/contracts/:id",
    component: Nfts,
    logged: true,
    props: [],
    redirect: "/login",
  },
  {
    path: "/login",
    component: Login,
    logged: false,
    props: [],
    redirect: "/",
  },
  {
    path: "/signup",
    component: Signup,
    hasTopBar: true,
    logged: false,
    hasDrawer: false,
    hasLogo: true,
    goBack: true,
    topBarLabel: i18n.t("signup.registration"),
    props: [],
  },
  {
    path: "/resetPassword",
    component: ResetPassword,
    logged: false,
    hasDrawer: false,
    hasTopBar: true,
    topBarLabel: i18n.t("topBar.reset_password"),
    topBarFunction: "/login",
    goBack: true,
    props: [],
  },
  {
    path: "/verifyEmail",
    component: EmailVerification,
    logged: false,
    hasDrawer: false,
    hasTopBar: false,
    props: [],
  },
  {
    path: "/recoverEmail",
    component: RecoverEmail,
    logged: false,
  },

  {
    path: "/redirects",
    component: Redirects,
    logged: false,
    hasDrawer: false,
    hasTopBar: false,
    props: [],
  },
  {
    path: "/onboarding",
    component: Onboarding,
    logged: false,
    onboarding: true,
    props: [],
  },
  {
    path: "/success",
    component: SuccessPage,
    logged: true,
    onboarding: false,
    hasTopBar: false,
    props: [],
  },
  {
    path: "/faq",
    component: Faq,
    unlogged: true,
    inDrawer: true,
    drawerIcon: "FEEDBACK",
    drawerLabel: "Faq",
    hasTopBar: true,
    topBarLabel: i18n.t("FAQ"),
    goBack: true,
    props: [],
    redirect: "/faq",
  },
];

//yup validations

//login page

export const loginInitialValues = {
  email: "",
  password: "",
};

export const loginValidation = Yup.object({
  email: Yup.string().email().required("inserire il nome"),
  password: Yup.string()
    .min(8, "la password deve avere minimo 8 caratteri")
    .max(16, "la password deve avere al massimo 16 caratteri")
    .required("insere la password"),
});

//sign-in page

//pec-phone verification page

export const pecVerificationInitialValues = {
  pec: "",
  fiscalCode: "",
};

// export const pecVerificationValidation = Yup.object({
//     pec: Yup.string().email().required("inserire la pec"),
//     // fiscalCode: Yup.string()
//     //     .length(16, "il codice fiscale deve avere 16 caratteri")
//     //     .required("inserire il codice fiscale"),
// });

// export const phoneVerificationInitialValuesStepOne = {
//     phone: "",
//     fiscalCode: "",
// };

// export const phoneVerificationValidationStepOne = Yup.object({
//     phone: Yup.string().required("inserire il nome"),
//     fiscalCode: Yup.string().matches(
//         /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i,
//     ),
//     //     .length(16, "il codice fiscale deve avere 16 caratteri")
//     //     .required("inserire il codice fiscale"),
// });

// export const phoneVerificationInitialValuesStepTwo = {
//     phone: "",
//     verificationCode: "",
// };

// export const phoneVerificationValidationStepTwo = Yup.object({
//     phone: Yup.string().required("inserire il nome"),
//     verificationCode: Yup.string()
//         .min(6, "il codice di verifica deve avere minimo 6 caratteri")
//         // .max(16, "la password deve avere al massimo 16 caratteri")
//         .required("inserire il codice di verifica"),
// });

//fiscal code

export const fiscalCodeInitialValues = {
  firstName: "",
  lastName: "",
  gender: "",
  date: "",
  birthplace: "",
  isItalian: "",
};

export const fiscalCodeValidation = Yup.object({
  firstName: Yup.string().required("inserire il nome"),
  lastName: Yup.string().required("inserire il cognome"),
  gender: Yup.string().required("inserire il sesso"),
  date: Yup.string().required("inserire la data di nascita"),
  birthplace: Yup.string().required("inserire il luogo di nascita"),
  isItalian: Yup.boolean(),
});

//changepassword profile page

export const changePasswordInitialValue = {
  previousPassword: "",
  newPassord: "",
  repeatPassord: "",
};

export const changePasswordValidation = Yup.object({
  previousPassword: Yup.string()
    .min(8, "la password deve avere minimo 8 caratteri")
    .max(16, "la password deve avere al massimo 16 caratteri")
    .required("insere la password"),
  newPassword: Yup.string()
    .min(8, "la password deve avere minimo 8 caratteri")
    .max(16, "la password deve avere al massimo 16 caratteri")
    .required("insere la password"),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("insere la password"),
});

//notarize page

// export const notarizationInitialValue = {
//     name: "",
//     description: "",
//     nft: 0,
//     private: false,
//     password: "",
//     multipleSign: false,
//     signatures: [],
// };

// export const notarizationValidation = Yup.object({
//     name: Yup.string().required("insere nome al documento"),
//     description: Yup.string(),
//     nft: Yup.number(),
//     private: Yup.boolean(),
//     password: Yup.string(),
//     signatures: Yup.array().when("multipleSign", {
//         is: true,
//         then: Yup.array().min(1).max(5),
//     }),
// });

// export const notarizationTextInitialValue = {
//     name: "",
//     text: "",
//     private: false,
//     alreadyHash: false,
// };

// export const notarizationTextValidation = Yup.object({
//     name: Yup.string().required("inserire titolo"),
//     text: Yup.string().required("inserire testo"),
// });

//contacts page

// export const contactsInvitationInitialValues = {
//     email: "",
// };

// export const contactsInvitationValidation = Yup.object({
//     email: Yup.string().email().required("inserire mail"),
// });

//add payment cards page

export const cardInitialValues = {
  owner: "",
  number: "",
  cardType: "",
  cvv: "",
  expiry: "",
};

export const cardValidation = Yup.object({
  owner: Yup.string().required("inserire nome"),
  number: Yup.string().required("inserire il numero della carta"),
  cardType: Yup.string().required("inserire il tipo della carta"),
  cvc: Yup.string().required("inserire cvc"),
  expiry: Yup.string().required("inserire data di scadenza"),
});

//payment page

export const purchaseInitialValues = {
  name: "",
  surname: "",
  email: "",
  phone: "",
  selectedCard: "",
  saveCard: false,
};

export const purchaseValidation = Yup.object({
  name: Yup.string().required("inserire nome"),
  surname: Yup.string().required("inserire il numero della carta"),
  email: Yup.string().required("inserire il tipo della carta"),
  phone: Yup.string().required("inserire cvc"),
  cardElement: Yup.object().required(),
  selectedPackage: Yup.object().required(),
});

//react
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

//components
import { StyledButton, CustomInput, Icons, Icon } from "../../components/index";
import { toast } from "react-toastify";

//i18n
import i18n from "../../imports/i18n";

//formik
import { Formik } from "formik";

//config
import { loginInitialValues, loginValidation } from "../../imports/config";

//style
import SplashScreen from "../../components/desktopComponent/splashScreen/splashScreen";
import { AuthContext } from "../../redux-observables/firebase/context";
import { TOAST_CONFIG } from "../../imports/constants";

const Login = props => {
  // const { loading } = props;

  const { user, loginUser, isLoading: loading } = useContext(AuthContext);

  const history = useHistory();

  const loginForm = [
    {
      label: i18n.t("forms.email"),
      type: "email",
      formName: "email",
      placeholder: i18n.t("forms_placeholders.email"),
    },
    {
      label: i18n.t("forms.password"),
      type: "password",
      formName: "password",
      placeholder: i18n.t("forms_placeholders.password"),
    },
  ];

  useEffect(() => {
    if (user?.logged) {
      toast.success(i18n.t("messages.succesfully_registered"), TOAST_CONFIG);
    }
  }, []);

  useEffect(() => {
    if (user?.logged) {
      props.history.push("/");
    }
  }, [user]);

  console.log("loading: ", loading);

  return loading ? (
    <SplashScreen type={"pending"} message={i18n.t("messages.login")} />
  ) : (
    <div className="box-border flex flex-col items-center justify-center w-full color-primary">
      <div className="flex flex-col items-center justify-center mt-3">
        <Icon
          name={Icons.FOREVER_BAMBU_LOGO}
          style={{
            width: 200,
            height: 200,
            // marginRight: 25,
          }}
        />
      </div>

      {/* <div className="text-4xl text-primary mb-60">{i18n.t("login.login")}</div> */}
      <Formik
        initialValues={loginInitialValues}
        validationSchema={loginValidation}
        onSubmit={values => {
          loginUser(values.email, values.password);
        }}
      >
        {({ handleChange, handleSubmit, values, errors }) => (
          <>
            <div className="flex flex-col items-center justify-center w-full h-full px-4">
              {loginForm.map((el, i) => (
                <CustomInput
                  key={i}
                  type={el.type}
                  label={el.label}
                  value={values[el.formName]}
                  placeholder={el.placeholder || null}
                  onChange={handleChange(el.formName)}
                  errors={errors[el.formName]}
                />
              ))}
            </div>
            <div className="flex flex-col items-center justify-around w-full h-48 mt-60">
              <StyledButton
                styleType="primary"
                onClick={handleSubmit}
                style={{ marginBottom: "15px" }}
              >
                {
                  //TODO: split user and admin login
                  i18n.t("login.admin_login")
                }
              </StyledButton>
              <div className="flex items-center justify-between w-full text-base text-center max-w-450 text-primary my-15">
                <div className="w-2/6 h-0 mt-1 border border-primary"></div>
                {i18n.t("login.otherwise")}
                <div className="w-2/6 h-0 mt-1 border border-primary"></div>
              </div>
              <StyledButton
                styleType="secondary"
                onClick={() => history.push("/signup")}
              >
                {i18n.t("login.signup")}
              </StyledButton>
            </div>
          </>
        )}
      </Formik>
      <div className="flex flex-col items-center justify-between mt-16 mb-8 no-underline">
        {/* <a
          className="mb-3 text-primary"
          href="mailto:info@bcode.cloud?subject=Problemi di accesso"
        >
          {i18n.t("forms.issues")}
        </a> */}
        <span
          className="mt-3 cursor-pointer text-primary"
          onClick={() => history.push("/resetPassword")}
        >
          {i18n.t("forms.forgot")}
        </span>
        <span
          className="mt-3 cursor-pointer text-primary"
          onClick={() => history.push("/faq")}
        >
          {"Faq"}
        </span>
      </div>
    </div>
  );
};

export default Login;

import { useMediaQuery } from "react-responsive";
import { ethers } from "ethers";
import CryptoJS from "crypto-js";

import { ipfsNode } from "../enhancers/createIpfsEnhancer";

import { useEffect, useRef } from "react";
import { format, parse } from "date-fns";
import {
  CONTRACTS,
  DATE_FORMAT,
  IPFS_GATEWAY,
  PRIVATE_FIELD,
} from "./constants";
import { getProvider } from "./blockchainConfig";

import { encrypt, decrypt } from "eccrypto";

import { toast } from "react-toastify";
import i18n from "./i18n";
import axios from "axios";
import { currentEnv } from "./config";

import { PablockSDK } from "pablock-sdk";
import pinataSDK from "@pinata/sdk";

import crypto from "crypto";

export const sdk = new PablockSDK({
  apiKey: process.env.REACT_APP_SDK_API,
  config: {
    env: process.env.REACT_APP_SDK_ENV,
    rpcProvider: process.env.REACT_APP_RPC_PROVIDER,
    debugMode: true,
    pablockContracts: {
      PABLOCK_TOKEN_ADDRESS: process.env.REACT_APP_PABLOCK_TOKEN_ADDRESS,
      PABLOCK_META_TRANSACTION: process.env.REACT_APP_PABLOCK_META_TRANSACTION,
      PABLOCK_NOTARIZATION: process.env.REACT_APP_PABLOCK_NOTARIZATION,
      PABLOCK_NFT: process.env.REACT_APP_PABLOCK_NFT,
      PABLOCK_MULTISIGN_FACTORY:
        process.env.REACT_APP_PABLOCK_MULTISIGN_FACTORY,
    },
  },
});

export const pinata = pinataSDK(
  process.env.REACT_APP_PINATA_API_KEY,
  process.env.REACT_APP_PINATA_SECRET_KEY,
);

export function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export const download = (fileName, file) => {
  let element = document.createElement("a");
  element.setAttribute("href", file);
  element.setAttribute("download", fileName);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

// export const Mobile = ({ children }) => {
//   const isMobile = useMediaQuery({ maxWidth: 599 }); //767
//   return isMobile ? children : null;
// };

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 600 }); //768
  return isDesktop ? children : null;
};

export const CheckIsMobile = () => {
  const isMobile = useMediaQuery({ maxWidth: 599 }); //767
  return isMobile ? true : false;
};

export async function getGasPrice() {
  if (currentEnv === "DEV") {
    let res = await axios.get("https://gasstation-mumbai.matic.today/");
    return res.data.fastest * 1000000000;
  } else if (currentEnv === "PROD") {
    let res = await axios.get("https://gasstation-mainnet.matic.network/");
    return res.data[process.env.REACT_APP_TX_SPEED] * 1000000000;
  } else {
    return 5000000000;
  }
}

//IPFS Functions
export const downloadFromIPFS = async (fileName, privateKey, ipfsAddress) => {
  let blob = await fetch(`${IPFS_GATEWAY}/${ipfsAddress}`).then(async r => {
    return await r.blob();
  });
  console.log("blob: ", blob);
  const link = document.createElement("a");
  link.download = `${fileName}`;
  if (privateKey) {
    let reader = new FileReader();
    reader.onload = async function () {
      let result = JSON.parse(reader.result);

      link.href = window.URL.createObjectURL(
        new Blob([
          new Uint8Array(
            await decryptData(cleanCryptedData(result), privateKey),
          ),
        ]),
      );

      link.click();
    };
    reader.readAsText(blob);
  } else {
    let reader = new FileReader();
    // reader.onload = function () {
    //     let dataUrl = reader.result;
    // };
    reader.readAsDataURL(blob);
    link.href = window.URL.createObjectURL(blob);

    link.click();
  }
};

export const getIPFSFile = async (ipfsAddress, callback) => {
  if (ipfsNode) {
    const chunks = [];
    for await (const chunk of ipfsNode.cat(ipfsAddress)) {
      chunks.push(chunk);
    }

    let blob = new Blob(chunks);

    if (callback) {
      let reader = new FileReader();
      reader.onload = function () {
        let dataUrl = reader.result;
        let base64 = dataUrl.replace("dataimage/pngbase64", "").split(",")[1];
        callback(base64);
      };
      reader.readAsDataURL(blob);
    }

    return window.URL.createObjectURL(blob);
  } else {
    return null;
  }
};

export const getPrivateIPFSFile = async (
  ipfsAddress,
  privateKey,
  isOwner,
  type,
  callback,
) => {
  if (ipfsNode) {
    const chunks = [];
    for await (const chunk of ipfsNode.cat(ipfsAddress)) {
      chunks.push(chunk);
    }

    let blob = new Blob(chunks);

    let reader = new FileReader();
    reader.onload = async function () {
      let result = JSON.parse(reader.result);

      let buff = isOwner
        ? await decryptData(cleanCryptedData(result), privateKey)
        : await simmetricDecryptData(result, hashPassword(privateKey), false);

      let binary = "";
      let len = buff.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(buff[i]);
      }
      callback(btoa(binary));

      // callback(btoa(String.fromCharCode.apply(null, buff)));
      // callback(reader.readAsDataURL(new Blob(buff)));

      // callback(
      //     window.URL.createObjectURL(
      //         new Blob(
      //             isOwner
      //                 ? [
      //                       new Uint8Array(
      //                           await decryptData(
      //                               cleanCryptedData(result),
      //                               privateKey,
      //                           ),
      //                       ),
      //                   ]
      //                 : [
      //                       new Uint8Array(
      //                           simmetricDecryptData(
      //                               result,
      //                               hashPassword(privateKey),
      //                               false,
      //                           ),
      //                       ).buffer,
      //                   ],
      //         ),
      //     ),
      // );
    };

    return reader.readAsText(blob);
  } else {
    return null;
  }
};

export async function addFileToIPFS(data, fileName, isJson = false) {
  try {
    if (isJson) {
      let { IpfsHash } = await pinata.pinJSONToIPFS(data, {
        pinataMetadata: { name: fileName },
      });
      return IpfsHash;
    } else {
      // formData.append("pinataMetadata", JSON.stringify({ name: data.name }));
      data.append("pinataMetadata", JSON.stringify({ name: fileName }));

      let {
        data: { IpfsHash },
      } = await axios.post(
        "https://api.pinata.cloud/pinning/pinFileToIPFS",
        data,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
            pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
            pinata_secret_api_key: process.env.REACT_APP_PINATA_SECRET_KEY,
          },
        },
      );

      return IpfsHash;
    }
  } catch (err) {
    console.log(err);
    return { error: "errors.ipfs_upload_error" };
  }
}

export async function addJSONToIPFS(json) {
  const doc = JSON.stringify(json);

  console.log(doc);

  const cid = await ipfsNode.add(doc);

  console.log("CID ==>", cid);

  return cid;
}

export function calculateStringHash(str) {
  return CryptoJS.SHA256(str).toString(CryptoJS.enc.Hex);
}

export function calculateFileHash(file, callback) {
  // let reader = new FileReader();

  // reader.onload = function (event) {
  //     console.log(event.target.result);
  //     const wordArray = CryptoJS.lib.WordArray.create(event.target.result);

  //     callback(CryptoJS.SHA256(wordArray).toString(CryptoJS.enc.Hex));
  // };

  // reader.readAsArrayBuffer(file);
  // console.log(reader);
  const wordArray = CryptoJS.lib.WordArray.create(file);
  const hash = CryptoJS.SHA256(wordArray).toString(CryptoJS.enc.Hex);

  if (callback) {
    callback(hash);
  } else {
    return hash;
  }
}

export function getFile(file, callback) {
  let reader = new FileReader();

  reader.onload = () => {
    if (callback) {
      callback({
        name: file.name,
        type: file.type,
        buffer: reader.result,
      });
    } else {
      return { name: file.name, type: file.type, buffer: reader.result };
    }
  };

  reader.readAsArrayBuffer(file);
}

export function formatTimestamp(ts) {
  return format(parse(ts, "t", new Date()), DATE_FORMAT);
}

export function truncStringPortion(str, firstCharCount, endCharCount) {
  let convertedStr = "";

  convertedStr += str.substring(0, firstCharCount);
  convertedStr += "...";
  convertedStr += str.substring(endCharCount, str.length);

  return convertedStr;
}

export function getContract(contract, wallet) {
  try {
    const polygonProvider = getProvider();

    const account = wallet.connect(polygonProvider);
    return new ethers.Contract(
      contract.address || process.env[`${contract.env}`],
      contract.abi,
      account,
    );
  } catch (e) {
    console.log(e);
    toast.error(i18n.t("errors.unable_to_connect"), {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
    return null;
  }
}

export function getContractFactory(contract, wallet) {
  const polygonProvider = getProvider();
  const account = wallet.connect(polygonProvider);
  return new ethers.ContractFactory(contract.abi, contract.bytecode, account);
}

export function hashPassword(password) {
  return crypto
    .createHash("sha256")
    .update(String(password))
    .digest("base64")
    .substring(0, 32);
}

export function encryptObj(data, password) {
  const encryptedData = {};

  const hashedPassword = hashPassword(password);

  PRIVATE_FIELD.forEach(key => {
    encryptedData[key] = simmetricEncryptData(data[key], hashedPassword);
  });

  return encryptedData;
}

export function decryptObj(data, password) {
  const decryptedData = {};

  const hashedPassword = hashPassword(password);

  PRIVATE_FIELD.forEach(key => {
    decryptedData[key] = simmetricDecryptData(data[key], hashedPassword);
  });

  return decryptedData;
}

export function simmetricEncryptData(data, hashedPassword) {
  const iv = crypto.randomBytes(16);
  const cipher = crypto.createCipheriv("aes-256-ctr", hashedPassword, iv);

  return {
    iv: iv.toString("hex"),
    content: Buffer.concat([cipher.update(data), cipher.final()]).toString(
      "hex",
    ),
  };
}

export function simmetricDecryptData(data, hashedPassword, returnStr = true) {
  const decipher = crypto.createDecipheriv(
    "aes-256-ctr",
    hashedPassword,
    Buffer.from(data.iv, "hex"),
  );

  const decrypted = Buffer.concat([
    decipher.update(Buffer.from(data.content, "hex")),
    decipher.final(),
  ]);

  return returnStr ? decrypted.toString() : decrypted;
}

export function encryptData(file, pubKey) {
  try {
    let publicKey = Buffer.from(pubKey.replace("0x", ""), "hex");

    return encrypt(publicKey, file).then(encrypted => {
      return encrypted;
    });
  } catch (err) {
    console.log(err);
    return null;
  }
}

export function decryptData(file, privKey) {
  try {
    let privateKey = Buffer.from(privKey.replace("0x", ""), "hex");

    return decrypt(privateKey, file).then(decrypted => {
      // console.log(
      //     "DECRYPTED FILE ==>",
      //     //  btoa(new TextDecoder("utf8").decode(decrypted)),
      //     decrypted.reduce((data, byte) => {
      //         return data + String.fromCharCode(byte);
      //     }, ""),
      // );
      return decrypted;
    });
  } catch (err) {
    console.log(err);
    return null;
  }
}

export function cleanCryptedData(obj) {
  Object.keys(obj).forEach(key => (obj[key] = Buffer.from(obj[key].data)));
  return obj;
}

/**
 *
 * @description Creates a promise that resolves after the given
 * milliseconds
 * @param {int} milliseconds
 */
export async function sleep(milliseconds) {
  return new Promise((resolve, reject) => {
    let timeout = setTimeout(() => {
      clearTimeout(timeout);
      timeout = null;
      resolve();
    }, milliseconds);
  });
}

export function isASCII(str) {
  return /^[\x00-\x7F]*$/.test(str);
}

export async function tokenIsOwnedByAddress(tokenId, address, wallet) {
  try {
    const pablockNFT = getContract(
      { address, ...CONTRACTS.PABLOCK_NFT },
      wallet,
    );
    return (await pablockNFT.ownerOf(tokenId)) === wallet.address;
  } catch {
    return false;
  }
}

export function getBase64(file, callback) {
  let reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = function () {
      if (callback) {
        return resolve(callback(reader.result));
      }
      return resolve(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
    reader.readAsDataURL(file);
  });
}

export function getBlob(file, callback) {
  let reader = new FileReader();
  reader.onload = function (e) {
    console.log("GET BLOBL ==>", new Uint8Array(e.target.result));
    let blob = new Blob([new Uint8Array(e.target.result)], {
      type: file.type,
    });
    if (callback) {
      callback(blob);
    }
    return blob;
  };
  reader.readAsArrayBuffer(file);
}

export function getArrayBufferFromFile(file, callback) {
  let reader = new FileReader();
  reader.onload = function (e) {
    let arrBuff = new Uint8Array(e.target.result);
    console.log("utils: ", arrBuff);
    if (callback) {
      callback(arrBuff);
    }
    return arrBuff;
  };
  console.log("somewhere");
  reader.readAsArrayBuffer(file);
}

export function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n) {
    u8arr[n - 1] = bstr.charCodeAt(n - 1);
    n -= 1; // to make eslint happy
  }
  return new File([u8arr], filename, { type: mime });
}

export const computeStyle = (style, type, variant, applyVariant) => {
  return `${style[type].base} ${
    style[type].variants[applyVariant ? variant : "default"]
  }`;
};

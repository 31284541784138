export const theme = {
  primary: {
    base: `text-xl text-left relative flex items-center  font-medium`,
    variants: {
      default: "",
      disabled: "text-disabledGrey",
      tooltip: "mr-4",
    },
  },
  secondary: {
    base: `text-xl text-left relative flex items-center text-primary font-medium`,
    variants: {
      default: "",
      disabled: "text-disabledGrey",
      tooltip: "mr-4",
    },
  },
  tertiary: {
    base: `text-lg text-left relative flex items-center text-primary font-medium`,
    variants: {
      default: "",
      disabled: "text-disabledGrey",
      tooltip: "mr-4",
    },
  },
};

import React from "react";

import Lottie from "react-lottie-player";
import { SmallLoader } from "../../imports/animations/index";

export default function Loader({ size = "medium", style = {} }) {
    const SIZE = {
        small: { width: 50, height: 50 },
        medium: { width: 100, height: 100 },
        large: { width: 150, height: 150 },
    };

    return (
        <div className="flex items-center justify-center w-full">
            <div style={{ ...SIZE[size], ...style }}>
                <Lottie loop={true} play animationData={SmallLoader} />
            </div>
        </div>
    );
}

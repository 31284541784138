//react
import React, { useReducer, useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";

//formik
import { Formik } from "formik";

// Style
import { colors, MAX_FILE_SIZE, TOAST_CONFIG } from "../../imports/constants";

// i18n
import i18n from "../../imports/i18n";

// Components
import {
  StyledButton,
  Icon,
  Icons,
  CustomInput,
  MessageBox,
  FileInput,
  SplashScreen,
} from "../../components/index";

//Imports
import { usePrevious, getBase64, getFile } from "../../imports/utils";

import { toast } from "react-toastify";
import { checkHashQuery, queryCreateNFT } from "./queries";
import { isEmpty } from "lodash";
import { Hash } from "pablock-sdk";
import { AuthContext } from "../../redux-observables/firebase/context";
import {
  fields,
  notarizationInitialValue,
  notarizationValidation,
} from "./FormType";

export default function Notarize(props) {
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const [zipFile, setZipFile] = useState();
  const [coverFile, setCoverFile] = useState();
  const [error, setError] = useState(false);

  const [topBarTitle, setTopBarTitle] = useState(i18n.t("notarize.new_nft"));

  function reducer(state, action) {
    switch (action.type) {
      case "openModal":
      case "loader":
        return { ...state, [action.type]: !state[action.type] };

      default:
        return { ...state, [action.type]: action.payload };
    }
  }
  const [state, dispatch] = useReducer(reducer, {
    openModal: false,
    status: 0,
    notarizationData: {},
    searchField: null,
    activeQuery: false,
    psd2Code: null,
    loader: false,
    stepNumber: 0,
  });

  const { status, notarizationData, loader, stepNumber } = state;

  const { isFetching: notarizing, refetch: createNFT } = useQuery(
    "createNFT",
    async () => {
      //console.log("in useQuery: ", notarizationData);
      setError(await queryCreateNFT(notarizationData));
    },
    { enabled: false },
  );

  const { isFetching: checkHashLoading, refetch: checkHash } = useQuery(
    "checkHash",
    async () => {
      //console.log("in useQuery: ", notarizationData);
      if (!checkHashLoading) {
        const res = await checkHashQuery(zipFile.hash);
        if (res) {
          // console.log("existing nft: ", nft.zipHash, zipFile.hash);
          toast.error("Nft gia' esistente!", TOAST_CONFIG);
          setZipFile(null);
          return;
        }
      }
    },
    { enabled: false },
  );

  // const { data: allNfts, isLoading: fetchingNfts } = useFirestoreQueryData(
  //   ["requestId"],
  //   query(collection(db, "nfts")),
  // );

  const prevLoading = usePrevious(notarizing);

  useEffect(() => {
    if (!isEmpty(notarizationData)) {
      createNFT();
    }
  }, [notarizationData]);

  useEffect(() => {
    if (!notarizing && prevLoading) {
      dispatch({ type: "loader", payload: true });
    }
  }, [notarizing, error, prevLoading]);

  useEffect(() => {
    if (zipFile) {
      checkHash();
    }
  }, [zipFile]);

  return notarizing || loader ? (
    <SplashScreen
      type={notarizing ? "pending" : loader && !error ? "success" : "failure"}
      message={
        notarizing
          ? i18n.t("messages.notarization_pending")
          : loader && !error
          ? i18n.t("messages.notarization_success")
          : i18n.t("messages.notarization_failed")
      }
      buttonClick={() => history.push(`/`)}
      buttonText={i18n.t("nft_detail.close")}
      text={
        notarizing
          ? i18n.t("messages.loading")
          : loader && !error
          ? i18n.t("messages.done")
          : i18n.t("messages.failed")
      }
    />
  ) : stepNumber === 0 ? (
    <div className="grid h-full text-center">
      <div
        //topBarTitle
        className={`text-3xl whitespace-no-wrap font-bold text-primary items-center my-auto`}
        style={{ wordBreak: "keep-all" }}
      >
        {i18n.t("notarize.cell_import")}
      </div>
      <div>
        <span className="text-sm font-medium text-black">
          {i18n.t("notarize.cell_import")}
        </span>
        <FileInput
          styleType="NFTUpload"
          // acceptingFiles="application/zip"
          acceptingFiles=".zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
          id="zipFile"
          onChange={e => {
            if (e.size > MAX_FILE_SIZE) {
              toast.error(i18n.t("verify_doc.max_size_exceeded"), TOAST_CONFIG);
            } else {
              let object = {};
              e.forEach(function (value, key) {
                object[key] = value;
              });

              if (
                ![
                  ".zip",
                  "application/octet-stream",
                  "application/zip",
                  "application/x-zip",
                  "application/x-zip-compressed",
                ].includes(object.file.type)
              ) {
                toast.error(
                  i18n.t("notarize.allowed_format", { format: ".zip" }),
                  TOAST_CONFIG,
                );
              } else {
                // console.log(e);
                getFile(object.file, ({ name, type, buffer }) =>
                  setZipFile({
                    file: e,
                    name,
                    hash: Hash.fromBuffer(buffer),
                  }),
                );
              }
            }
          }}
        >
          <div>
            <span className="text-grey">
              {zipFile
                ? i18n.t("notarize.change_file")
                : i18n.t("notarize.allowed_format", { format: ".zip" })}
              <br />
              {!zipFile && "Max 100MB"}
            </span>
            <br />
            {!zipFile && (
              <Icon
                fill={colors.primary}
                name={Icons.UPLOAD}
                style={{
                  width: 30,
                  height: 30,
                }}
              />
            )}
          </div>
        </FileInput>
        {zipFile && (
          <div className="flex justify-between w-full mx-auto mt-1 max-w-350">
            <div>
              <Icon
                fill={colors.primary}
                name={Icons.DOC}
                style={{
                  width: 30,
                  height: 30,
                }}
              />
              <span> {zipFile ? zipFile.name : ""} </span>
            </div>
            <div>
              <button
                onClick={() => {
                  setZipFile(null);
                }}
              >
                <Icon
                  name={Icons.CROSS}
                  fill={colors.primary}
                  style={{
                    width: 30,
                    height: 30,
                  }}
                  children=""
                />
              </button>
            </div>
          </div>
        )}
      </div>
      <div>
        <span className="text-sm font-medium text-black">
          {i18n.t("notarize.cover_import")}
        </span>
        <FileInput
          styleType="NFTUpload"
          acceptingFiles="image/*"
          id="svgFile"
          onChange={e => {
            if (e.size > MAX_FILE_SIZE) {
              //TODO: only zip accepted
              toast.error(i18n.t("verify_doc.max_size_exceeded"), TOAST_CONFIG);
            } else {
              let object = {};
              e.forEach(function (value, key) {
                object[key] = value;
              });

              if (!object.file.type.includes("image/")) {
                toast.error(
                  i18n.t("notarize.allowed_format", {
                    format: ".svg / jpeg / jpg / png",
                  }),
                  TOAST_CONFIG,
                );
              } else {
                getBase64(object.file).then(res => {
                  setCoverFile({
                    file: e,
                    name: object.file.name,
                    blob: res,
                  });
                });
              }
              //getFile(object.file, async () => {
            }
          }}
        >
          {!coverFile ? (
            <div>
              <span className="text-grey">
                {i18n.t("notarize.allowed_format", {
                  format: ".svg / jpeg / jpg / png",
                })}
                <br />
              </span>
              <br />
              <Icon
                fill={colors.primary}
                name={Icons.UPLOAD}
                style={{
                  width: 30,
                  height: 30,
                }}
              />
            </div>
          ) : (
            <img
              src={coverFile.blob}
              alt={i18n.t("loading")}
              className={`w-full h-24`}
            />
          )}
        </FileInput>
        {coverFile && (
          <div className="flex justify-between w-full mx-auto mt-1 max-w-350">
            <div>
              <Icon
                fill={colors.primary}
                name={Icons.DOC}
                style={{
                  width: 30,
                  height: 30,
                }}
              />
              <span> {coverFile ? coverFile.name : ""} </span>
            </div>
            <div>
              <button
                onClick={() => {
                  setCoverFile();
                }}
              >
                <Icon
                  name={Icons.CROSS}
                  fill={colors.primary}
                  style={{
                    width: 30,
                    height: 30,
                  }}
                  children=""
                />
              </button>
            </div>
          </div>
        )}
      </div>
      <div>
        <StyledButton
          styleType="primary"
          isDisabled={!zipFile || !coverFile}
          children={i18n.t("notarize.new_doc")}
          onClick={() => dispatch({ type: "stepNumber", payload: 1 })}
        ></StyledButton>
        <StyledButton
          styleType="secondary"
          children={i18n.t("notarize.go_back")}
          onClick={() => history.push("/")}
        ></StyledButton>
      </div>
    </div>
  ) : stepNumber === 1 ? (
    <div
      //className="grid h-full my-4"
      className="flex flex-col items-center justify-start h-full p-4"
    >
      <div
        //topBarTitle
        className={`text-3xl whitespace-no-wrap font-bold text-primary text-center items-center mb-4`}
        style={{ wordBreak: "keep-all" }}
      >
        {topBarTitle}
      </div>

      <Formik
        initialValues={notarizationInitialValue}
        validationSchema={notarizationValidation}
        onSubmit={values => {
          // dispatch({ type: "stepNumber", payload: 2 });
          dispatch({
            type: "notarizationData",
            //payload: { ...values, file, fileHash },
            payload: {
              ...values,
              zipFile,
              coverFile,
              owner: user.uid,
            },
          });
          //console.log("onSubmit: ", notarizationData);
          //notarize();
        }}
      >
        {({
          handleChange,
          handleSubmit,
          values,
          errors,
          isValid,
          dirty,
          setFieldValue,
        }) => (
          <>
            {status === 2 ? (
              <div className="box-border flex flex-col justify-start w-full pb-8 max-w-450">
                {
                  //FIX: It gives warning, but it works
                }
                <span className="flex-col text-xl font-medium text-black">
                  {i18n.t(values["name"])}
                </span>
                <img
                  className={`w-full h-40 rounded-20 mt-4`}
                  src={coverFile.blob}
                  alt="Loading"
                />
                <div className="flex-col w-full mx-auto mt-6 mb-3 max-w-450">
                  <Icon
                    fill={colors.primary}
                    name={Icons.DOC}
                    style={{
                      width: 30,
                      height: 30,
                    }}
                  />
                  <span> {zipFile ? zipFile.name : ""} </span>
                </div>
                <div className="flex-col w-full mx-auto mb-5 max-w-450">
                  <Icon
                    fill={colors.primary}
                    name={Icons.DOC}
                    style={{
                      width: 30,
                      height: 30,
                    }}
                  />
                  <span> {coverFile ? coverFile.name : ""} </span>
                </div>
                {fields.map(({ formName, label }) => (
                  <div key={formName} className="flex flex-col ">
                    {formName && (
                      <span className="my-3 font-medium text-black">
                        {`${i18n.t(formName)}*`}
                      </span>
                    )}
                    <span className="my-2 ml-5 font-normal text-black">
                      {values[label]}{" "}
                      {label === "carbonT" || label === "carbonY"
                        ? " t"
                        : label === "area"
                        ? " mq"
                        : ""}
                    </span>
                  </div>
                ))}
                <div className="bottom-0 w-full pb-4 mx-auto max-w-450">
                  <StyledButton
                    styleType="primary"
                    onClick={
                      // dispatch({ type: "status", payload: 2 });
                      handleSubmit
                    }
                  >
                    {i18n.t("notarize.confirm_notarize")}
                  </StyledButton>
                  <StyledButton
                    styleType="secondary"
                    //onClick={() => history.goBack()}
                    onClick={() => {
                      dispatch({ type: "status", payload: 1 });
                      setTopBarTitle(i18n.t("notarize.new_nft"));
                    }}
                  >
                    {i18n.t("notarize.go_back")}
                  </StyledButton>
                </div>
              </div>
            ) : status === 1 ? ( //add note
              <div
                className="flex flex-col justify-between h-full py-4"
                // className={`w-full flex flex-col justify-start items-center`}
                style={{ height: "calc(100vh - 80px)" }}
              >
                <div>
                  {/* <div
                    //topBarTitle
                    className={`text-3xl whitespace-no-wrap font-bold text-primary text-center items-center mt-auto mb-4`}
                    style={{ wordBreak: "keep-all" }}
                  >
                    {i18n.t("notarize.new_nft")}
                  </div> */}
                  <div className="flex-col w-full mx-auto mb-3 max-w-450">
                    <span className="mb-1 font-medium text-black">
                      {`${i18n.t("notarize.add_note")}`}
                    </span>
                    <MessageBox
                      charLimit={100}
                      styleType="secondary"
                      placeholder={i18n.t("notarize.description")}
                      value={values["note"]}
                      onChange={handleChange("note")}
                    />
                    <span className="mt-1 font-normal text-grey">
                      {i18n.t("notarize.note_note")}
                    </span>
                  </div>
                </div>

                <div className="bottom-0 w-full pb-4 mx-auto max-w-450">
                  <StyledButton
                    styleType="primary"
                    onClick={() => {
                      dispatch({ type: "status", payload: 2 });
                      setTopBarTitle(i18n.t("notarize.notarize_preview"));
                    }}
                  >
                    {i18n.t("notarize.add_note")}
                  </StyledButton>
                  <StyledButton
                    styleType="secondary"
                    //onClick={() => history.goBack()}
                    onClick={() => dispatch({ type: "status", payload: 0 })}
                  >
                    {i18n.t("notarize.go_back")}
                  </StyledButton>
                </div>
              </div>
            ) : (
              //input nft details

              <div className="box-border flex flex-col items-center justify-start w-full pb-8">
                <div className="flex-col w-full mx-auto mb-3 max-w-450">
                  <Icon
                    fill={colors.primary}
                    name={Icons.DOC}
                    style={{
                      width: 30,
                      height: 30,
                    }}
                  />
                  <span> {coverFile ? zipFile.name : ""} </span>
                </div>
                <div className="flex-col w-full mx-auto mb-5 max-w-450">
                  <Icon
                    fill={colors.primary}
                    name={Icons.DOC}
                    style={{
                      width: 30,
                      height: 30,
                    }}
                  />
                  <span> {coverFile ? coverFile.name : ""} </span>
                </div>
                <div className="w-full max-w-450">
                  {/* <span className="mb-1 font-medium text-black">
                    {`${i18n.t("notarize.cell_name")}*`}
                  </span> */}
                  <CustomInput
                    styleType="primary"
                    label={`${i18n.t("notarize.cell_name")}*`}
                    type={"text"}
                    value={values["name"]}
                    placeholder={i18n.t("notarize.cell_name")}
                    onChange={handleChange("name")}
                    errors={errors["name"]}
                    //tooltipText={i18n.t("notarize.name_tooltip")}
                  />
                  <span className="mb-1 font-medium text-black">
                    {`${i18n.t("notarize.description")}*`}
                  </span>
                  <MessageBox
                    charLimit={100}
                    styleType="secondary"
                    value={values["description"]}
                    placeholder={i18n.t("notarize.description")}
                    onChange={handleChange("description")}
                    errors={errors["description"]}
                  />
                  {/* <span className="mb-1 font-medium text-black">
                    {`${i18n.t("notarize.coordinates")}*`}
                  </span> */}
                  <CustomInput
                    styleType="primary"
                    label={`${i18n.t("notarize.coordinates")}*`}
                    type={"text"}
                    value={values["latitude"]}
                    placeholder={i18n.t("insert.latitude")}
                    onChange={handleChange("latitude")}
                    errors={errors["latitude"]}
                  />
                  <CustomInput
                    type={"text"}
                    value={values["longitude"]}
                    placeholder={i18n.t("insert.longitude")}
                    onChange={handleChange("longitude")}
                    errors={errors["longitude"]}
                  />
                  {/* <span className="mb-1 font-medium text-black">
                    {`${i18n.t("notarize.extension")}*`}
                  </span> */}
                  <CustomInput
                    styleType="primary"
                    label={`${i18n.t("notarize.extension")}*`}
                    type={"number"}
                    value={values["area"]}
                    placeholder={i18n.t("insert.extension")}
                    onChange={handleChange("area")}
                    errors={errors["area"]}
                    labelIcon={false}
                    icon={"(mq)"}
                  />

                  {/* <span className="mb-1 font-medium text-black">
                    {`${i18n.t("notarize.annually_co2")}*`}
                  </span> */}
                  <CustomInput
                    styleType="primary"
                    label={`${i18n.t("notarize.annually_co2")}*`}
                    type={"number"}
                    value={values["carbon"]}
                    placeholder={i18n.t("insert.co2")}
                    onChange={handleChange("carbonY")}
                    errors={errors["carbonY"]}
                    labelIcon={false}
                    icon={"(t)"}
                  />
                  {/* <span className="mb-1 font-medium text-black">
                    {`${i18n.t("notarize.total_co2")}*`}
                  </span> */}
                  <CustomInput
                    styleType="primary"
                    label={`${i18n.t("notarize.total_co2")}*`}
                    type={"number"}
                    value={values["carbon"]}
                    placeholder={i18n.t("insert.co2")}
                    onChange={handleChange("carbonT")}
                    errors={errors["carbonT"]}
                    labelIcon={false}
                    icon={"(t)"}
                  />
                </div>
                <StyledButton
                  styleType="primary"
                  isDisabled={!isEmpty(errors)}
                  onClick={() => dispatch({ type: "status", payload: 1 })}
                >
                  {i18n.t("notarize.create_nft")}
                </StyledButton>
                <StyledButton
                  styleType="secondary"
                  //onClick={() => history.goBack()}
                  onClick={() => dispatch({ type: "stepNumber", payload: 0 })}
                >
                  {i18n.t("notarize.go_back")}
                </StyledButton>
              </div>
            )}
          </>
        )}
      </Formik>
    </div>
  ) : (
    // : (
    "404"
  );
}

import i18n from "../../imports/i18n";

//formik
import * as Yup from "yup";
import { isEqual } from "lodash";
import { pick } from "lodash";

export const signupFormStepOne = {
  private: [
    {
      label: `${i18n.t("forms.name")}*`,
      type: "text",
      formName: "name",
      placeholder: i18n.t("forms_placeholders.name"),
    },
    {
      label: `${i18n.t("forms.surname")}*`,
      type: "text",
      formName: "surname",
      placeholder: i18n.t("forms_placeholders.surname"),
    },
    {
      label: `${i18n.t("forms.email")}*`,
      type: "email",
      formName: "email",
      placeholder: i18n.t("forms_placeholders.email"),
    },
    // {
    //   label: `${i18n.t("forms.email")}*`,
    //   type: "text",
    //   formName: "companyName",
    //   placeholder: i18n.t("forms_placeholders.email"),
    // },
  ],
  // company: [
  //     {
  //         label: `${i18n.t("forms.company_name")}*`,
  //         type: "text",
  //         formName: "company_name",
  //         placeholder: i18n.t("forms_placeholders.company_name"),
  //     },
  //     {
  //         label: `${i18n.t("forms.vat")}*`,
  //         type: "text",
  //         formName: "vat",
  //         placeholder: i18n.t("forms_placeholders.vat"),
  //     },
  //     {
  //         label: `${i18n.t("forms.sdi")}*`,
  //         type: "text",
  //         formName: "sdi",
  //         placeholder: i18n.t("forms_placeholders.sdi"),
  //     },
  //     {
  //         label: `${i18n.t("forms.businessAddress")}*`,
  //         type: "text",
  //         formName: "businessAddress",
  //         placeholder: i18n.t("forms_placeholders.businessAddress"),
  //     },
  //     {
  //         label: `${i18n.t("forms.email")}*`,
  //         type: "email",
  //         formName: "email",
  //         placeholder: i18n.t("forms_placeholders.email"),
  //     },
  // ],
};

export const signupFormStepTwo = [
  {
    label: `${i18n.t("forms.username")}*`,
    type: "text",
    formName: "username",
    placeholder: i18n.t("forms_placeholders.username"),
  },
  {
    label: `${i18n.t("forms.password")}*`,
    type: "password",
    formName: "password",
    placeholder: i18n.t("forms_placeholders.password"),
  },
  {
    label: `${i18n.t("forms.repeat_password")}*`,
    type: "password",
    formName: "repeatPassword",
    placeholder: i18n.t("forms.repeat_password"),
  },
  {
    label: `${i18n.t("forms.secondaryPassword")}*`,
    type: "password",
    formName: "secondaryPassword",
    placeholder: i18n.t("forms_placeholders.password"),
  },
  //   {
  //     label: `${i18n.t("forms.privacy_header")}*`,
  //     type: "onlyText",
  //   },
  {
    label: `${i18n.t("forms.privacy_check")}`,
    type: "check",
    formName: "checks.privacy",
    link: "https://firebasestorage.googleapis.com/v0/b/forever-bambu-dev.appspot.com/o/Informativa_forever_bambu.pdf?alt=media&token=4c97ab81-20b0-4ae2-9827-f01716f75fd3",
    // link: "https://www.pablock.it/privacy-policy-demo/",
  },
  // {
  //   label: `${i18n.t("forms.marketing_check")}`,
  //   type: "check",
  //   formName: "checks.marketing",
  //   // link: "https://www.pablock.it/privacy-policy-demo/",
  // },
  {
    label: `${i18n.t("forms.use_and_condition_check")}`,
    type: "check",
    formName: "checks.use_and_condition",
    // link: "https://www.pablock.it/privacy-policy-demo/",
  },
  {
    label: `${i18n.t("forms.newsletter_check")}`,
    type: "check",
    formName: "checks.newsletter",
  },
  // {
  //   label: `${i18n.t("forms.blockchain_check")}*`,
  //   type: "check",
  //   formName: "checks.blockchain",
  //   link: "https://www.pablock.it/privacy-policy-demo/",
  //   doubleCheck: true,
  //   modalMessage: i18n.t("forms.blockchain_double_check"),
  // },
  // {
  //   label: `${i18n.t("forms.commercial_check")}*`,
  //   type: "check",
  //   formName: "checks.commercial",
  //   link: "https://www.pablock.it/privacy-policy-demo/",
  // },

  {
    label: `${i18n.t("forms.check_needed")}`,
    type: "onlyText",
  },
  //   {
  //     label: `${i18n.t("forms.privacy_footer")}*`,
  //     type: "onlyText",
  //   },
];

export const signupInitialValues = {
  private: {
    name: "",
    surname: "",
    email: "",
    companyName: "",
    username: "",
    password: "",
    secondaryPassword: "",
    repeatPassword: "",
    checks: {
      privacy: false,
      // marketing: false,
      use_and_condition: false,
      newsletter: false,
      // blockchain: false,
    },
    //importWallet: false,
    //mnemonic: "",
    company: false,
  },
  //   company: {
  //     company_name: "",
  //     vat: "",
  //     sdi: "",
  //     businessAddress: "",
  //     email: "",
  //     username: "",
  //     password: "",
  //     secondaryPassword: "",
  //     repeatPassword: "",
  //     checks: {
  //       privacy: false,
  //       condition: false,
  //       commercial: false,
  //       blockchain: false,
  //     },
  //     importWallet: false,
  //     mnemonic: "",
  //  },
};

export const signupValidationStepOne = {
  private: Yup.object({
    name: Yup.string().required("inserire il nome"),
    surname: Yup.string().required("inserire il cognome"),
    email: Yup.string().email().required("inserire il nome"),

    // companyName: Yup.string().when("company", {
    //   is: true,
    //   then: Yup.string().test(
    //     "companyName",
    //     "Inserire nome azienda",
    //     str => str.length,
    //   ),
    // }),
    //companyName: Yup.string().required("inserire il nome dell'azienda"),
  }),
  //   company: Yup.object({
  //     company_name: Yup.string().required("inserire la ragione sociale"),
  //     vat: Yup.number().required("inserire la partita iva"),
  //     sdi: Yup.string().required("inserire il codice sdi"),
  //     businessAddress: Yup.string().required("inserire la sede legale"),
  //     email: Yup.string().email().required("inserire il nome"),
  //     mnemonic: Yup.string().when("importWallet", {
  //       is: true,
  //       then: Yup.string().test(
  //         "mnemonic",
  //         "Inserire mnemonica",
  //         str => str && str.split(" ").length === 12,
  //       ),
  //     }),
  //   }),
};

export const signupInitialValuesForCheck = {
  1: {
    private: {
      name: "",
      surname: "",
      email: "",
      companyName: "",
    },
    // company: {
    //   company_name: "",
    //   vat: "",
    //   sdi: "",
    //   businessAddress: "",
    //   email: "",
    // },
  },
  2: {
    private: {
      username: "",
      password: "",
      secondaryPassword: "",
      repeatPassword: "",
      checks: {
        privacy: false,
        // marketing: false,
        use_and_condition: false,
        newsletter: false,
        // blockchain: false,
      },
    },
    // company: {
    //   username: "",
    //   password: "",
    //   secondaryPassword: "",
    //   repeatPassword: "",
    //   checks: {
    //     privacy: false,
    //     condition: false,
    //     commercial: false,
    //     blockchain: false,
    //   },
    //   importWallet: false,
    //   mnemonic: "",
    // },
  },
};

export const signupValidationStepTwo = {
  private: Yup.object({
    name: Yup.string().required("inserire il nome"),
    surname: Yup.string().required("inserire il cognome"),
    email: Yup.string().email().required("inserire il nome"),
    username: Yup.string().required("inserire il nome"),
    password: Yup.string()
      .min(8, "la password deve avere minimo 8 caratteri")
      .max(16, "la password deve avere al massimo 16 caratteri")
      .required("insere la password"),
    secondaryPassword: Yup.string()
      .min(8, "la password deve avere minimo 8 caratteri")
      .max(16, "la password deve avere al massimo 16 caratteri")
      .required("insere la password"),
    repeatPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("insere la password"),
    checks: Yup.object({
      privacy: Yup.bool().oneOf([true], "La flag dev'essere spuntata"),
      // condition: Yup.bool().oneOf([true], "La flag dev'essere spuntata"),
      // blockchain: Yup.bool().oneOf([true], "La flag dev'essere spuntata"),
    }),
  }),
  //   company: Yup.object({
  //     company_name: Yup.string().required("inserire la ragione sociale"),
  //     vat: Yup.number().required("inserire la partita iva"),
  //     sdi: Yup.string().required("inserire il codice sdi"),
  //     businessAddress: Yup.string().required("inserire la sede legale"),
  //     email: Yup.string().email().required("inserire il nome"),
  //     username: Yup.string().required("inserire il nome"),
  //     password: Yup.string()
  //       .min(8, "la password deve avere minimo 8 caratteri")
  //       .max(16, "la password deve avere al massimo 16 caratteri")
  //       .required("insere la password"),
  //     secondaryPassword: Yup.string()
  //       .min(8, "la password deve avere minimo 8 caratteri")
  //       .max(16, "la password deve avere al massimo 16 caratteri")
  //       .required("insere la password"),
  //     repeatPassword: Yup.string()
  //       .oneOf([Yup.ref("password"), null], "Passwords must match")
  //       .required("insere la password"),
  //     checks: Yup.object({
  //       privacy: Yup.bool().oneOf([true], "La flag dev'essere spuntata"),
  //       condition: Yup.bool().oneOf([true], "La flag dev'essere spuntata"),
  //       blockchain: Yup.bool().oneOf([true], "La flag dev'essere spuntata"),
  //     }),
  //   }),
};

export function checkFieldCompletition(values, type, step) {
  const obj = signupInitialValuesForCheck[step][type];

  return isEqual(pick(values, Object.keys(obj)), obj);
}

import React from "react";

//components
import { CircleIconButton, Icons, Icon } from "../index";

//style
// import "./defaultCard.scss";
import { colors } from "../../imports/constants";
import i18n from "../../imports/i18n";

function UsersCard({
  title = null,
  imgSrc = null,
  onClick = null,
  optionsClick = null,
}) {
  return (
    <div
      className={`cursor-pointer rounded-20 mb-4 w-full h-full p-1 flex max-w-450 justify-center items-center flex-col box-border border-lightGrey border border-solid bg-primaryGradientw`}
      style={{
        boxShadow: "0px 8px 20px -12px rgba(0, 0, 0, 0.25)",
      }}
      onClick={() => onClick && onClick()}
    >
      {/* <div
        className={`w-full text-xl flex justify-between items-center box-border pl-0 pr-0 flex-col overflow-hidden text-black font-semibold transition ease-in-out delay-150`}
      > */}
      <div
        className={`w-full p-2 flex flex-row items-center font-medium box-border text-base justify-between text-black transition ease-in-out delay-150`}
      >
        {/* <div className=""> */}
        {imgSrc && (
          <div>
            <img src={imgSrc} alt="Profile Pic" className="w-10 h-10 mr-4" />
          </div>
        )}
        {title}

        {optionsClick && (
          <CircleIconButton
            size="small"
            onClick={() => optionsClick()}
            icon={
              <Icon
                name={Icons.OPTIONS}
                fill={colors["black"]}
                style={{
                  width: 15,
                  height: 15,
                }}
              />
            }
            disabled={!optionsClick}
            styleType={"secondary"}
            className={`bg-transparent ml-auto shadow-none`}
          />
        )}
        {/* </div> */}
      </div>
      {/* </div> */}
    </div>
  );
}

export default UsersCard;

import { collection, query, where, getDocs } from "firebase/firestore";
import { uniq, isEmpty } from "lodash";
import { db } from "../redux-observables/firebase/firebase";

export async function getUsersByAddrs(addresses) {
  if (!isEmpty(addresses)) {
    // console.log("addresses: ", addresses);
    addresses = uniq(addresses);
    addresses = addresses.map(addr => addr.toLowerCase());
    const q = query(collection(db, "users"), where("address", "in", addresses));
    const { docs } = await getDocs(q);
    // console.log("docs: ", docs);
    // console.log(docs[1]?.data());
    const response = docs.map(doc => doc.data());

    // console.log("user data from addresses: ", docs[0]?.data());
    return response;
  }
}

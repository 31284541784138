import {
  checkActionCode,
  applyActionCode,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updateEmail,
  sendEmailVerification,
  updateCurrentUser,
  getAdditionalUserInfo,
  getAuth,
} from "firebase/auth";
import { connectStorageEmulator } from "firebase/storage";
import { useContext } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ResetPassword } from "..";
import { TOAST_CONFIG } from "../../imports/constants";
import i18n from "../../imports/i18n";
import { AuthContext } from "../../redux-observables/firebase/context";

export default function RecoverEmail() {
  // Localize the UI to the selected language as determined by the lang
  // parameter.

  let restoredEmail = null;
  const history = useHistory();
  const { resetPassword } = useContext(AuthContext);

  useEffect(() => {
    if (history.location.state.oobCode) {
      const actionCode = history.location.state.oobCode;
      const auth = getAuth();

      // console.log("auth: ", auth);
      // console.log("actionCode: ", actionCode);

      // Confirm the action code is valid.
      checkActionCode(auth, actionCode)
        .then(info => {
          // Get the restored email address.
          restoredEmail = info["data"]["email"];
          // Revert to the old email.
          return applyActionCode(auth, actionCode);
        })
        .then(async () => {
          await resetPassword(restoredEmail);
        })
        .catch(error => {
          // Invalid code.
          // console.log(error);
          // updateCurrentUser(auth)
          //   .then(userRecord => {
          //     // See the UserRecord reference doc for the contents of userRecord.
          //     console.log("Successfully updated user", userRecord.toJSON());
          //   })
          //   .catch(error => {
          //     console.log("Error updating user:", error);
          //   });

          toast.error(i18n.t("errors.unable_to_restore_email"), TOAST_CONFIG);
        });
    }
  }, []);

  return "recover email page";
}

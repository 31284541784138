import { useState, useEffect } from "react";

export const useEnv = () => {
    const [isProd, setIsProd] = useState(false);

    useEffect(() => {
        if (process.env.REACT_APP_ENV === "PROD") {
            setIsProd(true);
        }
    }, []);

    return { isProd };
};

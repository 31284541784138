import * as Yup from "yup";

export const fields = [
  { formName: "notarize.cell_name", label: "name" },
  { formName: "notarize.description", label: "description" },
  { formName: "notarize.coordinates", label: "latitude" },
  { formName: "", label: "longitude" },
  { formName: "notarize.extension", label: "area" },
  { formName: "notarize.annually_co2", label: "carbonY" },
  { formName: "notarize.total_co2", label: "carbonT" },
];

export const notarizationInitialValue = {
  name: "",
  description: "",
  note: "",
  area: "",
  carbonY: "",
  carbonT: "",
  latitude: "",
  longitude: "",
};

export const notarizationValidation = Yup.object({
  name: Yup.string().required("Inserire nome al documento"),
  description: Yup.string().required("Inserire descrizione"),
  area: Yup.number().required("Inserire area"),
  carbonY: Yup.number().required("Inserire carbon"),
  carbonT: Yup.number().required("Inserire carbon"),
  latitude: Yup.number().required("Inserire latitudine"),
  longitude: Yup.number().required("Inserire longitudine"),
});

//react
import React from "react";
import { colors } from "../../imports/constants";

//components
import { Icons, Icon, CustomTooltip, CircleIconButton } from "../index";

//style
//import "./customLabel.scss";

//utils
import { computeStyle, truncStringPortion } from "../../imports/utils";

import { toast } from "react-toastify";
import i18n from "../../imports/i18n";
import { theme } from "./customLabel.theme";

export const CustomLabel = ({
  label = "",
  textComponent = null,
  styleType = "primary",
  topRightItem = null,
  bottomRightItem = null,
  textCut = false,
  bottomLine = true,
  tooltipText = false,
  disabled = false,
  copy = "",
  labelStyle = {},
  style = {},
  rowContainerStyle = {},
  outerLabelClass = "",
}) => {
  let processedText = "";

  if (textCut) {
    if (typeof textComponent === "string" && textComponent.length > 20) {
      processedText = truncStringPortion(
        textComponent,
        4,
        textComponent.length - 4,
      );
    }
  }

  const copyValue = () => {
    if (copy) {
      // let copyText = document.querySelector(`#${copy}`);
      navigator.clipboard.writeText(textComponent);

      toast.success(i18n.t(`messages.copied`, { string: textComponent }), {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const truncate = value =>
    value.substring(0, 12) + "..." + value.substring(value.length - 12);

  const labelClass = computeStyle(theme, styleType, "tooltip", tooltipText);

  return (
    <div
      style={style}
      className={`w-full flex flex-col justify-center items-center box-border mt-4 mb-4`}
    >
      <div
        //row-container
        className="flex flex-col items-center justify-center w-full h-full"
        style={(rowContainerStyle, { paddingTop: "1%" })}
      >
        {label && (
          <div
            //label-container (pb-4 removed )

            className={`${outerLabelClass} flex items-center justify-between w-full pb-4 `}
          >
            <div
              //TODO
              className={`${labelClass} ${disabled ? "text-disabledGrey" : ""}`}
              style={labelStyle}
            >
              {label}
              {tooltipText && (
                <CustomTooltip tooltipText={tooltipText}>
                  <CircleIconButton
                    icon={
                      <Icon
                        name={Icons.INFO}
                        fill={colors["black"]}
                        style={{
                          width: 20,
                          height: 20,
                        }}
                      />
                    }
                    styleType="secondary"
                  />
                </CustomTooltip>
              )}
            </div>
            <div>{topRightItem}</div>
          </div>
        )}
        {!!textComponent && (
          <div
            //text-container
            className="box-border flex items-center justify-between w-full pb-2 pl-2 border-0 border-black"
          >
            <span
              className={
                "text-left relative flex items-center text-black font-normal text-sm break-all"
              }
            >
              {processedText || copy ? truncate(textComponent) : textComponent}
            </span>
            <div>
              {copy ? (
                <CircleIconButton
                  onClick={copyValue}
                  className="cursor-pointer"
                  icon={
                    <Icon
                      name={Icons.COPY}
                      fill={colors.primary}
                      style={{
                        width: 15,
                        height: 15,
                      }}
                    />
                  }
                  styleType="secondary"
                />
              ) : (
                bottomRightItem
              )}
            </div>
          </div>
        )}
      </div>

      {bottomLine && (
        <div
          //CHECK
          //className={`bottom-line-${styleType}`}
          className={`${styleType === "tertiary" ? "h-px w-full bg-grey" : ""}`}
        />
      )}
    </div>
  );
};

import * as Yup from "yup";

export const recoveryPasswordValidation = Yup.object({
    primaryPassword: Yup.string()
        .min(8, "la password deve avere minimo 8 caratteri")
        .max(16, "la password deve avere al massimo 16 caratteri")
        .required("insere la password"),
    secondaryPassword: Yup.string()
        .min(8, "la password deve avere minimo 8 caratteri")
        .max(16, "la password deve avere al massimo 16 caratteri")
        .required("insere la password"),
});

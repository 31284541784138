//react
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

//material
//import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

//components
import { Icons, Icon, CircleIconButton } from "../index";

//style
// import "./drawerMenu.scss";
import { colors } from "../../imports/constants";

//config
import { routes } from "../../imports/config";
import i18n from "../../imports/i18n";

import { CheckIsMobile, getContract } from "../../imports/utils";
import { getProvider } from "../../imports/blockchainConfig";
import { CONTRACTS } from "../../imports/constants";
import { utils } from "ethers";
import { AuthContext } from "../../redux-observables/firebase/context";

import packageJson from "../../../package.json";

export const DrawerMenu = ({
  isOpen,
  toggleDrawer,
  logout,
  user,
  loadFailed,
}) => {
  const history = useHistory();
  const { signOut } = useContext(AuthContext);
  const isMobile = CheckIsMobile();

  const [leftOp, setLeftOp] = useState("0");

  useEffect(() => {
    const polygonProvider = getProvider();

    const checkBalance = async () => {
      if (polygonProvider && !user.needRecover) {
        const pablockToken = getContract(CONTRACTS.PABLOCK_TOKEN, user.wallet);

        try {
          console.log(
            "Checking balance ...",
            parseFloat(
              utils.formatEther(
                await pablockToken.balanceOf(user.wallet.address),
              ),
            ),
          );
          setLeftOp(
            parseFloat(
              utils.formatEther(
                await pablockToken.balanceOf(user.wallet.address),
              ),
            ),
          );
        } catch (e) {
          console.log(e);
          loadFailed("user", "errors.unable_to_connect");
        }
      }
    };

    checkBalance();
  }, []);

  return (
    <div
      className={
        " fixed overflow-hidden bg-black bg-opacity-25 inset-0 transform ease-in-out " +
        (isOpen
          ? " z-10 transition-opacity opacity-100 duration-500 translate-x-0  "
          : " hidden transition-all delay-50 max-w-600 opacity-0 translate-x-100")
      }
      style={isMobile ? {} : { marginLeft: "calc(50% - 300px)" }}
    >
      <div
        className={`h-full relative flex flex-col items-start justify-start bg-white`}
        style={{ paddingBottom: "20%", width: "280px" }}
      >
        <div
          className=""
          style={{
            paddingTop: "3%",
            paddingLeft: "5%",
            paddingBottom: "5%",
          }}
        >
          <CircleIconButton
            onClick={() => toggleDrawer(false)}
            icon={
              <Icon
                name={Icons.CROSS}
                fill={colors.primary}
                style={{ width: 30, height: 30 }}
              />
            }
            styleType={"secondary"}
          />
        </div>

        <List
          //drawer-list-container
          className="box-border flex flex-col justify-start h-full sm:pb-44"
        >
          {routes
            .filter(el => el.inDrawer)
            .map((route, i) => {
              if ((route.admin && user.role === "admin") || !route.admin) {
                return (
                  <ListItem
                    key={i}
                    onClick={() => {
                      if (!route.disabled) {
                        history.push(route.path);
                        toggleDrawer(false);
                      }
                    }}
                    disabled={route.disabled}
                    style={{ cursor: "pointer" }}
                  >
                    <ListItemIcon className="my-4">
                      <Icon
                        name={Icons[route.drawerIcon]}
                        fill={colors.primary}
                        secondaryfill={colors["secondary"]}
                        style={{ width: 30, height: 30 }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      className="text-lg font-medium drawer-label-text text-primary"
                    >
                      {route.drawerLabel}
                      {route.notification && (
                        <div
                          //notification
                          className="absolute top-0 w-2 h-2 rounded-50 bg-lightBlue"
                          style={{
                            left: "100px",
                          }}
                        ></div>
                      )}
                    </ListItemText>
                  </ListItem>
                );
              }
            })}
          <ListItem onClick={signOut}>
            <ListItemIcon className="my-4">
              <Icon
                name={Icons.LOGOUT}
                fill={colors.primary}
                style={{ width: 30, height: 30 }}
              />
            </ListItemIcon>
            <ListItemText
              disableTypography
              className="text-lg font-medium cursor-pointer drawer-label-text text-primary "
              //classes={{ primary: "font-bold" }}
            >
              {i18n.t("drawer.logout")}
            </ListItemText>
          </ListItem>
        </List>
        {user?.role === "admin" && (
          <span
            className={`absolute bottom-0 flex items-center justify-center w-full pb-1 mb-18 text-xl ${
              parseInt(leftOp) ? "text-green " : "text-red"
            }`}
            style={{
              maxWidth: "260px",
            }}
          >
            {i18n.t("home.left_op", { op: leftOp })}
          </span>
        )}

        <div
          //drawer-footer
          className="absolute bottom-0 flex flex-col items-center justify-around w-full h-24 bg-primary"
        >
          <div className="flex flex-col items-center justify-start w-full ">
            <span className="flex text-lg font-bold text-secondary">
              {i18n.t("forever_bambu")}
            </span>
            <span className="flex text-sm font-medium text-secondary">
              {`v${packageJson.version}`}
            </span>
          </div>

          <span className="flex text-sm font-medium text-secondary">
            {i18n.t("powered_by_bcode")}
          </span>
        </div>
      </div>
    </div>
  );
};

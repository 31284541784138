//formik
import * as Yup from "yup";

export const firstStepValidation = Yup.object({
    email: Yup.string().required("Inserire email"),
});

export const secondStepValidation = Yup.object({
    password: Yup.string()
        .min(8, "la password deve avere minimo 8 caratteri")
        .max(16, "la password deve avere al massimo 16 caratteri")
        .required("insere la password"),
    checkPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("insere la password"),
});

import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { getAuth, applyActionCode } from "firebase/auth";
//i18n
import i18n from "../../imports/i18n";
import { Icons, Icon } from "../../components/index";

export default function EmailVerification() {
  const history = useHistory();
  const { refetch } = useQuery("emailVerification", async () => {}, {
    enabled: false,
  });

  const [verification, setVerification] = useState("success");

  useEffect(() => {
    if (verification === "success" || verification === "failed") {
      setTimeout(() => {
        history.push("/login");
      }, 5000);
    }
  }, [verification]);

  useEffect(() => {
    if (history.location.state.oobCode) {
      const actionCode = history.location.state.oobCode;
      const auth = getAuth();
      applyActionCode(auth, actionCode)
        .then(resp => {
          setVerification("success");
          console.log("all done");
        })
        .catch(error => {
          console.log(error);
          setVerification("failed");
        });
      //}
    }
  }, []);

  return verification === "pending" ? (
    <div>Email Verification Pending</div>
  ) : verification === "success" ? (
    <div className="box-border flex flex-col items-center justify-center w-full my-30 color-primary">
      <span className="text-2xl text-center color-black">
        {i18n.t("messages.verification_success")}
      </span>

      <Icon
        name={Icons.VERIFICATION_SUCCESS}
        style={{
          width: 250,
          height: 250,
        }}
      />
    </div>
  ) : (
    <div className="box-border flex flex-col items-center justify-center w-full my-30 color-primary">
      <span className="text-2xl text-center color-black">
        {i18n.t("messages.verification_failed")}
      </span>

      <Icon
        name={Icons.VERIFICATION_FAILED}
        style={{
          width: 250,
          height: 250,
        }}
      />
    </div>
  );
}

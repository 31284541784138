export const theme = {
    primary: {
        base: `text-secondary bg-primary shadow-primary rounded-full p-1 `,
        variants: {
            default: "",
            disabled: "shadow-none text-lightGrey border-0 bg-disabledGrey",
        },
    },
    secondary: {
        base: ``,
        variants: {
            default: "",
            disabled: "shadow-none text-lightGrey border-0 bg-disabledGrey",
        },
    },
};

/* Page showing specific user data */

//react
import React, { useEffect, useState } from "react";

//components
import { CustomLabel, DocumentImage } from "../../components";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";

//Lottie
import { Loading } from "../../imports/animations/index";
import Lottie from "react-lottie-player";

// import "./nftDetail.scss";

//i18n
import i18n from "../../imports/i18n";

//carousel
import "react-responsive-carousel/lib/styles/carousel.min.css";

//Firebase
import {
  useFirestoreQueryData,
  useFirestoreDocument,
} from "@react-query-firebase/firestore";
import { query, collection, where, doc } from "firebase/firestore";
import { db } from "../../redux-observables/firebase/firebase";

// install Swiper modules
// SwiperCore.use([Pagination]);

const UserDetails = props => {
  const { match } = props;

  const userId = match.params.id;

  const details = [
    "email",
    "username",
    "createdAt",
    "owned_nft",
    "owned_nft_names",
    "address",
  ];

  const [nfts, setNfts] = useState(null);
  //const { user, recoverWallet } = useContext(AuthContext);

  const { data: userInfos, isLoading } = useFirestoreDocument(
    ["users", userId],
    doc(db, "users", userId),
  );

  // const { data: nfts, isLoading: fetchingNfts } = useFirestoreQueryData(
  //   ["requestId"],
  //   query(collection(db, "nfts"), where("owner", "==", userId.toLowerCase())),
  // );
  const { data: allNfts, isLoading: fetchingNfts } = useFirestoreQueryData(
    ["requestId"],
    query(collection(db, "nfts")),
  );

  const [userDetails, setUserDetails] = useState(userInfos);
  // console.log("userInfos: ", userInfos?.data());

  useEffect(() => {
    if (allNfts && userInfos) {
      const userNfts = [];
      allNfts.forEach(nft => {
        if (nft.owner.toLowerCase() === userInfos.data().address?.toLowerCase())
          userNfts.push(nft);
      });
      setNfts(userNfts);
    }
  }, [allNfts, userInfos]);

  useEffect(() => {
    if (userInfos && nfts) {
      const nfts_names = nfts.map(key => {
        return key.cellData.name;
      });

      const infos = {
        ...userInfos.data(),
        owned_nft: nfts.length,
        owned_nft_names: nfts_names.length
          ? nfts_names.join(", ")
          : i18n.t("userDetails.no_nft"),
      };
      setUserDetails(infos);
    }
  }, [userInfos, nfts]);

  // useEffect(() => {
  //   if (documentId) {
  //     fetchSingleDocument({ docId: documentId });
  //   }
  // }, []);
  // console.log(details);
  // console.log("userDetails: ", userDetails);

  return isLoading || !userDetails ? (
    <div className={`w-full relative`} style={{ height: "calc(100vh-80px" }}>
      <Lottie className="loading-spinner" play animationData={Loading} />
    </div>
  ) : (
    <div className={`w-full relative`} style={{ height: "calc(100vh-80px" }}>
      <div
        //img-container
        className="flex items-center justify-center w-full mb-8"
      >
        <DocumentImage src={userDetails.propic} type={"image/svg+xml"} />
      </div>

      {details.map(key => {
        // console.log(userDetails[key]?.toString());
        return (
          <CustomLabel
            key={key}
            styleType={"tertiary"}
            label={i18n.t(`profile.${key}`)}
            textComponent={
              key === "createdAt"
                ? new Date(userDetails[key]).toLocaleDateString("it-IT")
                : userDetails[key]?.toString()
            }
            copy={key === "address"}
          />
        );
      })}
    </div>
  );
};

export default UserDetails;

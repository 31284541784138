import axios from "axios";
import { doc, setDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import {
  CONTRACTS,
  IPFS_GATEWAY,
  NOTARIZATION_STATUS,
} from "../../imports/constants";
import { addFileToIPFS, sdk } from "../../imports/utils";
import { db, functions } from "../../redux-observables/firebase/firebase";

export async function queryCreateNFT(notarizationData) {
  try {
    console.log("Notarize query: ", notarizationData);

    const {
      name,
      area,
      carbonY,
      carbonT,
      description,
      latitude,
      longitude,
      note,
      zipFile,
      coverFile,
    } = notarizationData;

    let metaTx = null;
    let requestId = null;
    let zipFileIPFSAddress = "";
    let coverIPFSAddress = "";

    const siteData = {
      siteName: name,
      area: parseInt(area),
      carbonProducedAnnually: parseInt(carbonY),
      carbonProducedTotal: parseInt(carbonT),
      coordinates: [degToDms(latitude), degToDms(longitude)],
    };

    //TODO: convert latitude and longitude from float to Coordinates

    /*string siteName;
        uint256 area;
        uint256 carbonProduced;
        Coordinates coordinates;*/

    zipFileIPFSAddress = await addFileToIPFS(zipFile.file, zipFile.name);
    if (typeof zipFileIPFSAddress !== "string" && zipFileIPFSAddress.error) {
      return true;
    }
    coverIPFSAddress = await addFileToIPFS(coverFile.file, coverFile.name);

    if (typeof coverIPFSAddress !== "string" && coverIPFSAddress.error) {
      return true;
    }

    console.log(zipFileIPFSAddress, coverIPFSAddress);

    let dataToStringify = {
      name: name,
      description: description,
      image: `${IPFS_GATEWAY}/${coverIPFSAddress}`,
      external_url: `${IPFS_GATEWAY}/${zipFileIPFSAddress}`,
    };

    let tokenURI = await addFileToIPFS(
      dataToStringify,
      `${name}_metadata.json`,
      true,
    );

    if (typeof tokenURI !== "string" && tokenURI.error) {
      return true;
    }

    notarizationData.tokenURI = `${IPFS_GATEWAY}/${tokenURI}`;

    metaTx = await sdk.prepareTransaction(
      {
        address: process.env[CONTRACTS.FOREVER_BAMBU.env],
        abi: CONTRACTS.FOREVER_BAMBU.abi,
        name: "ForeverBambuNFT",
        version: "0.0.1",
      },
      "mintToken",
      [sdk.getWalletAddress(), notarizationData.tokenURI, siteData],
    );

    requestId = await sdk.executeAsyncTransaction(metaTx, {
      webhookUrl: `${process.env.REACT_APP_FIREBASE_ENDPOINT}/saveReceipt`,
      verbose: true,
    }); //optionals webhook address

    console.log("after execute: ", requestId);

    if (!requestId) {
      return true; //error = true
    }

    await setDoc(doc(db, "nfts", requestId), {
      id: requestId,
      metaTx,
      requestId,
      owner: sdk.getWalletAddress().toLowerCase(),
      status: NOTARIZATION_STATUS.PENDING,
      ipfsAddresses: { zip: zipFileIPFSAddress, cover: coverIPFSAddress },
      zipHash: zipFile.hash,
      cellData: {
        name,
        area,
        carbonY,
        carbonT,
        description,
        latitude,
        longitude,
        note,
        tokenURI,
      },
      createdAt: Date.now(),
      updatedAt: Date.now(),
      transfer: { underTransfer: false },
    });
  } catch (error) {
    console.log("got error: ", error);
    return { error };
  }
}

export async function checkHashQuery(hash) {
  // const checkHash = httpsCallable(functions, `helloWorld/${hash}`);
  // const res = await checkHash({ hash });

  const { data } = await axios.get(
    `${process.env.REACT_APP_FIREBASE_ENDPOINT}/checkHash/${hash}`,
    // `http://localhost:5001/forever-bambu-dev/europe-west3/api/checkHash/${hash}`,
  );

  return data;
}

function degToDms(dd) {
  let sign = "";
  if (dd < 0) {
    sign = "-";
    dd = -dd;
  }
  // first, compute the total number of seconds: degrees * 60 minutes/degree * 60 seconds/minute
  let totalSeconds = dd * 3600;
  let degrees = Math.floor(dd);
  totalSeconds -= degrees * 3600;
  // totalSeconds now represents the fractional degrees
  let minutes = Math.floor(totalSeconds / 60);
  totalSeconds -= minutes * 60;
  return [sign ? -degrees : degrees, minutes, Math.round(totalSeconds)];
}

import { useReducer, useState } from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { Icons, Icon, CustomInput } from "../../components";
import { colors } from "../../imports/constants";
import i18n from "../../imports/i18n";

export default function Faq(props) {
  const faq_arguments = [
    { title: i18n.t("faq.0_title") },
    { title: i18n.t("faq.1_title") },
    { title: i18n.t("faq.2_title") },
    {
      title: i18n.t("faq.3_title"),
    },
    { title: i18n.t("faq.4_title") },
    { title: i18n.t("faq.5_title") },
    { title: i18n.t("faq.6_title") },
    { title: i18n.t("faq.7_title") },
    { title: i18n.t("faq.8_title") },
    { title: i18n.t("faq.9_title") },
    { title: i18n.t("faq.10_title") },
  ];

  function reducer(state, action) {
    switch (action.type) {
      case typeof action.type === "number":
        return {
          ...state,
          [action.type]: action.payload,
        };

      default:
        return { ...state, [action.type]: action.payload };
    }
  }
  const [filteredFaq, setFilteredFaq] = useState(faq_arguments);
  const handleFilter = e => {
    setFilteredFaq(
      faq_arguments.filter(faq =>
        e ? faq.title.toLowerCase().includes(e.toLowerCase()) : true,
      ),
    );
  };

  const [state, dispatch] = useReducer(reducer, {
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    10: false,
  });

  return (
    <div className="p-30">
      <CustomInput
        placeholder={i18n.t("home.search_document")}
        icon={
          <Icon
            fill={colors["black"]}
            name={Icons.SEARCH}
            style={{
              width: 30,
              height: 30,
            }}
          />
        }
        onChange={handleFilter}
        //onFocus={resetFilter}
      />
      {filteredFaq.map(({ title }, i) => {
        // console.log(arg);
        return (
          <div key={i} className="flex flex-col my-4">
            <label
              className={`flex flex-row items-center p-2 text-lg font-semibold  cursor-pointer min-h-20 rounded-20 ${
                state[i] ? "bg-lightGrey text-black" : "bg-primary text-white"
              }`}
              onClick={() => {
                faq_arguments.forEach((_, i) =>
                  dispatch({ type: i, payload: false }),
                );
                dispatch({ type: i, payload: !state[i] });
              }}
            >
              <Icon
                name={Icons.ARROW}
                fill={state[i] ? colors.primary : colors.white}
                style={{
                  width: 20,
                  height: 16,
                  transform: state[i] ? "rotate(270deg)" : "rotate(180deg)",
                }}
              />
              <span className="ml-2">{title}</span>
            </label>
            <label
              className={`whitespace-pre-wrap m-4 ${!state[i] && "hidden"}`}
            >
              <Trans
                i18nKey={"faq." + i + "_text"}
                values={{ link: i18n.t("faq." + i + "_link") }}
                components={{ a: <a /> }}
              />
            </label>
          </div>
        );
      })}
      {/* {faq_arguments.map(({ arg, vis }) => {
        console.log(arg, vis);
        return (
          <div key={arg} className="flex flex-col">
            <label
              className="text-xl font-semibold text-primary"
              onClick={() => {}}
            >
              {i18n.t("faq." + arg + "_title")}
            </label>
            <label className={`whitespace-pre-wrap ${!vis && "hidden"}`}>
              {i18n.t("faq." + arg + "_text")}
            </label>
          </div>
        );
      })} */}
    </div>
  );
}

import ipfs from "ipfs";

export let ipfsNode = null;

export const createIpfsEnhancer =
  () =>
  createStore =>
  (...args) => {
    const store = createStore(...args);
    ipfs.create().then(node => {
      node.bootstrap
        .add(
          "/ip4/213.136.90.96/tcp/4001/ipfs/QmeMTajwQLn7JruhJXZshdEdYNPTjRtEJXvDJUa7FRDbLU",
          // "/ip4/127.0.0.1/tcp/4001/ipfs/QmQPeNsJPyVWPFDVHb77w8G42Fvo15z4bG2X8D2GhfbSXc",
        )
        .then(() => {});
      ipfsNode = node;
    });

    return store;
  };

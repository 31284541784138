import React from "react";
import Lottie from "react-lottie-player";
import { Loading } from "../../imports/animations";

export default function LoadingSpinner() {
    return (
        <div
            id="loadingSpinner"
            className="absolute left-0 flex items-center justify-center w-full h-full bg-darkPrimary"
        >
            <Lottie
                className="absolute w-48 h-48"
                style={{
                    top: "calc(50%-100px)",
                    left: "calc(50%-100px)",
                    zIndex: "99",
                }}
                play
                animationData={Loading}
            />
        </div>
    );
}

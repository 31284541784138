//react
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";

//formik
import { Formik } from "formik";

//config
import {
  signupFormStepOne,
  signupFormStepTwo,
  signupValidationStepOne,
  signupInitialValues,
  signupValidationStepTwo,
  checkFieldCompletition,
} from "./formValidation";

//i18n
import i18n from "../../imports/i18n";

//components
import {
  StyledButton,
  CustomInput,
  SplashScreen,
  CustomCheckbox,
  Icons,
  Icon,
} from "../../components/index";
import { AuthContext } from "../../redux-observables/firebase/context";

const Signup = props => {
  const history = useHistory();

  const { user, registerUser, isLoading: loading } = useContext(AuthContext);

  const [show, setShow] = useState(false);
  // const [isCompany, setIsCompany] = useState(false);
  const [savedValues, setSavedValues] = useState(null);

  const handleClick = () => {
    setShow(!show);
  };

  useEffect(() => {
    console.log("user logged: ", user?.emailVerified);
    if (user?.emailVerified) {
      setTimeout(() => {
        history.push("/login");
      }, 3000);
    }
  }, [user, history]);

  return loading ? (
    <SplashScreen
      type={"pendingSignup"}
      text={i18n.t("messages.account_creation")}
      message={i18n.t("messages.please_wait")}
    />
  ) : user?.emailVerified ? (
    <div className="box-border flex flex-col items-center justify-center w-full my-30 color-primary">
      {/* <span className="text-5xl font-semibold text-primary">
        {i18n.t("signup.registration")}
      </span> */}

      <span className="text-2xl text-center color-black">
        {i18n.t("signup.email_alert")}
      </span>

      <Icon
        name={Icons.EMAIL_SENT}
        style={{
          width: 250,
          height: 250,
        }}
      />
      <p className="text-2xl text-center color-black">
        {i18n.t("signup.click_link")}
      </p>
      <p className="text-2xl text-center color-black">
        {i18n.t("signup.redirect")}
      </p>
    </div>
  ) : (
    <div className="box-border flex flex-col items-center justify-around w-full pb-30 color-primary">
      {/* <div className="text-5xl font-semibold text-primary pb-60">
        {i18n.t("signup.registration")}
      </div> */}
      <div className="flex flex-col items-center justify-center ">
        <Icon
          name={Icons.FOREVER_BAMBU_LOGO}
          style={{
            width: 200,
            height: 120,
            // marginRight: 25,
          }}
        />
      </div>

      <Formik
        initialValues={
          // !show
          //     ?
          savedValues || signupInitialValues["private"]
          // : signupInitialValuesStepTwo
        }
        validationSchema={
          !show
            ? signupValidationStepOne["private"]
            : signupValidationStepTwo["private"]
        }
        onSubmit={values => {
          if (!show) {
            handleClick();
          } else {
            setSavedValues(values);

            registerUser(values);
          }
        }}
      >
        {({ handleSubmit, values, errors, setFieldValue }) => (
          <>
            <div className="flex flex-col items-center justify-center w-full">
              {/* {!show && (
                <DoubleSwitch
                  leftValue={i18n.t("selector.private")}
                  rightValue={i18n.t("selector.company")}
                  value={isCompany}
                  onChange={setIsCompany}
                />
              )} */}
              {console.log(values, errors)}
              {signupFormStepOne["private"].map(
                (el, i) =>
                  !show && (
                    <CustomInput
                      key={i}
                      type={el.type}
                      label={el.label}
                      formName={el.formName}
                      value={
                        // el.type === "check"
                        //     ? check
                        //     :
                        values[el.formName] || false
                      }
                      placeholder={el.placeholder || null}
                      onChange={value =>
                        // el.type === "check"
                        //     ? setCheck
                        //     :
                        setFieldValue(el.formName, value)
                      }
                      errors={errors[el.formName]}
                    />
                  ),
              )}
              {show &&
                signupFormStepTwo.map((el, i) =>
                  el.type !== "onlyText" ? (
                    <CustomInput
                      key={i}
                      type={el.type}
                      label={el.label}
                      value={
                        // el.type === "check"
                        // ? check
                        // :
                        values[el.formName]
                      }
                      placeholder={el.placeholder || null}
                      onChange={value =>
                        // el.type === "check"
                        //     ? setCheck
                        //     :
                        setFieldValue(el.formName, value)
                      }
                      errors={errors[el.formName]}
                      link={el.link}
                      modalMessage={el.modalMessage}
                      doubleCheck={el.doubleCheck}
                    />
                  ) : (
                    <p
                      key={i}
                      className="w-full text-xs text-left max-w-450 color-black"
                    >
                      {el.label}
                    </p>
                  ),
                )}
              {!show && (
                <div className="flex flex-col items-start justify-center w-full my-3 max-w-450">
                  {values.company && (
                    <div>
                      <CustomInput
                        //type="multiline"
                        label={`${i18n.t("selector.company")}*`}
                        formName={"companyName"}
                        value={values.companyName}
                        onChange={e => setFieldValue("companyName", e)}
                        placeholder={i18n.t("forms_placeholders.company_name")}
                        errors={errors["companyName"]}
                      />
                      <span className="text-sm text-primary">
                        {i18n.t("forms.company_hint")}
                      </span>
                    </div>
                  )}
                  <CustomCheckbox
                    label={i18n.t("forms.company_check")}
                    checked={!values.company}
                    onClick={() => setFieldValue("company", !values.company)}
                  />
                </div>
              )}
            </div>
            <div
              className="flex flex-col items-center justify-around w-11/12 h-1/3 mt-60"
              style={{
                maxHeight: "230px",
              }}
            >
              <StyledButton
                styleType="primary"
                onClick={handleSubmit}
                isDisabled={
                  !isEmpty(errors) ||
                  checkFieldCompletition(values, "private", !show ? 1 : 2)
                }
              >
                {!show ? i18n.t("signup.next_step") : i18n.t("signup.signup")}
              </StyledButton>
              <div className="flex items-center justify-between w-full my-4 text-base font-light text-center max-w-450 color-primary">
                <div className="w-1/3 h-0 mt-1 border border-primary"></div>
                {i18n.t("signup.otherwise")}
                <div className="w-1/3 h-0 mt-1 border border-primary"></div>
              </div>
              <StyledButton
                styleType="secondary"
                onClick={() => {
                  !show ? history.push("/login") : handleClick();
                }}
              >
                {!show ? i18n.t("signup.login") : i18n.t("signup.prev_step")}
              </StyledButton>
            </div>
          </>
        )}
      </Formik>
      {/* <div className="signup-links">
                <div className="signup-link" onClick={() => history.push("/")}>
                    {i18n.t("forms.issues")}
                </div>
                <div className="signup-link" onClick={() => history.push("/")}>
                    {i18n.t("forms.forgot")}
                </div>
            </div> */}
    </div>
  );
};

export default Signup;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
//components
import {
  Loader,
  UsersCard,
  CustomInput,
  Icon,
  Icons,
} from "../../components/index";

import { colors, DEFAULT_PROPIC } from "../../imports/constants";
//Firebase
import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import { query, collection } from "firebase/firestore";
import { db } from "../../redux-observables/firebase/firebase";
import i18n from "../../imports/i18n";

function UserManager(props) {
  const history = useHistory();

  const { data: users, isLoading } = useFirestoreQueryData(
    ["users"],
    query(collection(db, "users")),
  );
  const [filteredUsers, setFilteredUsers] = useState(users);

  const handleFilter = e => {
    setFilteredUsers(
      users.filter(user =>
        e ? user.username.toLowerCase().includes(e.toLowerCase()) : true,
      ),
    );
  };

  useEffect(() => {
    !isLoading && handleFilter();
  }, [users]);

  return isLoading ? (
    <Loader type={""} />
  ) : filteredUsers?.length > 0 ? (
    <div
      className={`w-full flex mt-4 justify-center flex-col bg-secondary items-center`}
      // style={{ minHeight: "calc(100vh-80px" }}
    >
      <CustomInput
        placeholder={i18n.t("home.search_document")}
        icon={
          <Icon
            fill={colors["black"]}
            name={Icons.SEARCH}
            style={{
              width: 30,
              height: 30,
            }}
          />
        }
        onChange={handleFilter}
        //onFocus={resetFilter}
      />
      <div
        //nft-card
        className="flex flex-col items-center justify-center w-full"
      >
        {filteredUsers.map((item, i) => {
          return (
            <UsersCard
              key={i}
              //title={item.username}
              imgSrc={item.propic || DEFAULT_PROPIC}
              //isDisabled={item?.tokenIds}
              title={item.username}
              onClick={() => {
                if (item) history.push(`/user-details/${item.uid}`);
              }}
              // optionsClick={() => console.log("optionsClicked ", item)}
            />
          );
        })}
      </div>
    </div>
  ) : (
    <div
      className={`w-full flex mt-4 justify-center items-center flex-col bg-secondary`}
      // style={{ minHeight: "calc(100vh-80px" }}
    >
      <CustomInput
        placeholder={i18n.t("home.search_document")}
        icon={
          <Icon
            fill={colors["black"]}
            name={Icons.SEARCH}
            style={{
              width: 30,
              height: 30,
            }}
          />
        }
        onChange={handleFilter}
        //onFocus={resetFilter}
      />
      <span> {`${i18n.t("home.no_users")}`} </span>
    </div>
  );
}

// function mapDispatchToProps(dispatch) {
//   return bindActionCreators(Actions, dispatch);
// }

// export default connect(({ user, notarizations }) => {
//   return {
//     user,
//     //nfts: notarizations.list.filter(el => el.nft),
//     //loading: notarizations.loading,
//     //latestAdded: notarizations.latestAdded,
//     //error: notarizations.error,
//   };
// }, mapDispatchToProps)(UserManager);
export default UserManager;

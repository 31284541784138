/* Homepage: every minted nft will be listed here
divided by status (based on tabs choice) */

//react
import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

//Firebase
import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import { query, collection, where } from "firebase/firestore";
import { db } from "../../redux-observables/firebase/firebase";

//components
import {
  SquareIconButton,
  CustomInput,
  Icons,
  Icon,
  DefaultCard,
  Loader,
  StyledButton,
  CustomModal,
} from "../../components/index";

import { colors } from "../../imports/constants";

//i18n
import i18n from "../../imports/i18n";

import Lottie from "react-lottie-player";
import { Loading } from "../../imports/animations";
import { Formik } from "formik";
import { AuthContext } from "../../redux-observables/firebase/context";
import { recoveryPasswordValidation } from "./FormType";
import { sdk } from "../../imports/utils";
import { getUsersByAddrs } from "../../imports/utilsQuery";

export default function Home(props) {
  const { loading, dark } = props;

  const history = useHistory();
  const { user, recoverWallet } = useContext(AuthContext);

  //TODO: need to add where for non admin user
  const { data: nfts, isLoading } = useFirestoreQueryData(
    ["nfts"],
    user.role === "admin"
      ? query(collection(db, "nfts"))
      : query(
          collection(db, "nfts"),
          where("owner", "==", sdk.getWalletAddress().toLowerCase()),
        ),
  );

  const [owners, setOwners] = useState();
  const [buttonFilter, setButtonFilter] = useState("wallet");
  const [nftFilter, setFilterDoc] = useState({
    wallet: [],
    pending: [],
    transferred: [],
  });

  const getOwners = async () => {
    if (nftFilter && buttonFilter === "transferred") {
      const tmpOwner = await getUsersByAddrs(
        nftFilter[buttonFilter].map(nft => nft.owner),
      );

      let ownersObj = {};
      tmpOwner?.forEach(owner => (ownersObj[owner?.address] = owner?.username));
      setOwners(ownersObj);
    }
  };

  const handleFilter = e => {
    const filters = {
      wallet: nfts =>
        nfts.filter(
          nft =>
            nft.owner.toLowerCase() === sdk.getWalletAddress().toLowerCase() &&
            nft.status === "success" &&
            (e
              ? nft.cellData.name.toLowerCase().includes(e.toLowerCase())
              : true),
        ),
      pending: nfts =>
        nfts.filter(
          nft =>
            nft.status === "pending" &&
            (e
              ? nft.cellData.name.toLowerCase().includes(e.toLowerCase())
              : true),
        ),
      transferred: nfts =>
        nfts.filter(
          nft =>
            (nft.status === "transferred" ||
              nft.owner.toLowerCase() !==
                sdk.getWalletAddress().toLowerCase()) &&
            (e
              ? nft.cellData.name.toLowerCase().includes(e.toLowerCase())
              : true),
        ),
    };
    if (nfts) {
      setFilterDoc({
        ...nftFilter,
        [buttonFilter]: filters[buttonFilter](nfts),
      });
    } else {
      setFilterDoc({ wallet: [], pending: [], transferred: [] });
    }
  };

  useEffect(() => handleFilter(), [buttonFilter, nfts]);
  useEffect(() => getOwners(), [nftFilter]);

  const squareButtons = [
    {
      label: i18n.t("home.wallet"),
      icon: "NFT",
      notification: false,
      status: "wallet",
    },
    {
      label: i18n.t("home.waiting"),
      icon: "WAITING",
      notification: true,
      status: "pending",
    },
    {
      label: i18n.t("home.transferred"),
      icon: "SOCIALMEDIA",
      notification: true,
      status: "transferred",
    },
  ];

  const NftCards = () => {
    return nftFilter[buttonFilter].length > 0 ? (
      nftFilter[buttonFilter].map((item, i) => {
        const icon = (
          <Icon
            fill={colors.primary}
            name={Icons["SOCIALMEDIA"]}
            style={{ width: 20, height: 25 }}
          />
        );

        return (
          <div
            key={i}
            //nft-card
            className="flex items-center justify-center w-full pb-6"
          >
            <DefaultCard
              title={item.cellData.name}
              info={[
                buttonFilter === "transferred" && {
                  key: "nft_detail.current_owner",
                  value:
                    owners?.[item.owner.toLowerCase()] ||
                    i18n.t("nft_detail.deleted_user"),
                },
                { key: "contracts.status", value: item.status },
                {
                  key: "contracts.date",
                  value: new Date(item.createdAt).toLocaleDateString("it-IT"),
                },
              ]}
              topIcon={icon}
              //isDisabled={item?.tokenIds}
              onClick={() => {
                if (item) history.push(`/nft-detail/${item.requestId}`);
              }}
              //optionsClick={() => console.log("optionsClicked ", item._id)}
            />
          </div>
        );
      })
    ) : (
      <div
        className={`w-full flex justify-center items-center text-xl text-center font-normal text-grey`}
      >
        {user.role === "admin"
          ? i18n.t(`nft_cards.no_nft_found`)
          : i18n.t("nft_cards.no_nft_received")}
      </div>
    );
  };

  const recoverModal = () => {
    return (
      <div
        id="recoverModal"
        className={`flex flex-col justify-start items-center`}
      >
        <div
          //title
          className="flex justify-center w-full pt-8 text-4xl font-bold text-black"
        >
          {i18n.t("recover_wallet.recover")}
        </div>

        {loading ? (
          <div id="loadingSpinner" style={{ backgroundColor: colors.white }}>
            <Lottie
              loop={true}
              play
              animationData={Loading}
              className={"loading-spinner"}
              style={{ position: "relative" }}
            />
          </div>
        ) : (
          <>
            <div
              //text
              className="flex justify-center w-full pb-8 text-xl text-black"
            >
              {i18n.t("recover_wallet.recovery_text")}
            </div>
            <Formik
              initialValues={{
                primaryPassword: "",
                secondaryPassword: "",
              }}
              validationSchema={recoveryPasswordValidation}
              onSubmit={values => {
                recoverWallet(values);
              }}
            >
              {({
                handleChange,
                handleSubmit,
                values,
                errors,
                setFieldValue,
              }) => (
                <div
                  //input-container
                  className="flex flex-col items-center justify-center w-full h-full"
                >
                  <CustomInput
                    type={"password"}
                    label={i18n.t("recover_wallet.primary_password")}
                    value={values.primaryPassword}
                    onChange={e => setFieldValue("primaryPassword", e)}
                    errors={errors.primaryPassword}
                    dark={dark}
                  />
                  <CustomInput
                    type={"password"}
                    label={i18n.t("recover_wallet.recovery_password")}
                    value={values.secondaryPassword}
                    onChange={e => setFieldValue("secondaryPassword", e)}
                    errors={errors.secondaryPassword}
                    dark={dark}
                  />
                  <StyledButton onClick={handleSubmit}>
                    {i18n.t("recover_wallet.recover_wallet")}
                  </StyledButton>
                </div>
              )}
            </Formik>
          </>
        )}
      </div>
    );
  };

  return (
    <div
      className={`w-full flex justify-center flex-col bg-secondary`}
      // style={{ minHeight: "calc(100vh-80px" }}
    >
      <CustomModal opened={user.needRecover} content={recoverModal()} />
      <div
        className={`w-full pt-5 box-border flex flex-col items-center justify-start z-1 bg-secondary`}
      >
        <div
          className={`flex justify-center items-center pb-0 box-border w-full`}
        >
          {user.role === "admin" &&
            squareButtons.map((item, i) => {
              const selected = buttonFilter === item.status;
              const icon = (
                <Icon
                  fill={selected ? colors.secondary : colors.lightGrey}
                  secondaryfill={selected ? colors.primary : colors.black}
                  name={Icons[item.icon]}
                  style={{
                    width: 30,
                    height: 30,
                  }}
                />
              );

              return (
                <div
                  key={i}
                  className="box-border flex flex-col items-center w-16 mx-5"
                >
                  <SquareIconButton
                    icon={icon}
                    isSelected={selected}
                    onClick={() => {
                      setButtonFilter(item.status);
                      // if (item.status === "explore") {
                      //     setActiveQuery(true);
                      // }
                    }}
                    //notification={item.notification}
                  />
                  <div
                    className={`pt-5 w-24 text-base text-center font-semibold ${
                      selected ? "text-primary" : "text-black"
                    }`}
                    style={{ wordBreak: "break-word" }}
                  >
                    {item.label}
                  </div>
                </div>
              );
            })}
        </div>
        <CustomInput
          placeholder={i18n.t("home.search_nft")}
          icon={
            <Icon
              fill={colors["black"]}
              name={Icons.SEARCH}
              style={{
                width: 30,
                height: 30,
              }}
            />
          }
          //onChange={searchFilter}
          onChange={handleFilter}
          //onFocus={resetFilter}
        />
      </div>

      <div
        className="flex flex-col w-full pb-8 overflow-y-auto"
        style={{ height: "calc(100vh-380px" }}
      >
        {isLoading ? <Loader type={""} /> : <NftCards />}
      </div>
    </div>
  );
}

import React from "react";

import { Icon, Icons } from "../../index";

//style
import { colors } from "../../../imports/constants";
// import "./purchasePackageContent.scss";

const PurchasePackageContent = ({
    packageDescription = null,
    packagePrice = null,
    packagePromo = null,
    ...props
}) => {
    return (
        <div
            //purchase-package-content-app
            className="w-full h-full flex flex-row justify-between text-center"
        >
            <div
                //purchase-package-content-left
                className="flex flex-col justify-around text-xl text-left text-primary font-regular"
            >
                <div>{packageDescription}</div>
                <div>
                    <Icon
                        //purchase-package-content-icon
                        className="w-10 h-10 fill-primary"
                        name={Icons.CHECK}
                        fill={colors.secondary}
                    />
                </div>
            </div>
            <div
                //purchase-package-content-right
                className="flex flex-col justify-end mb-2 text-right"
            >
                {packagePromo ? (
                    <>
                        <div
                            //purchase-package-content-price
                            className="text-xl font-regular line-through"
                        >
                            {packagePrice}
                        </div>
                        <div
                            //purchase-package-content-promo
                            className="text-5xl text-primary font-semibold"
                        >
                            {packagePromo}
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            //purchase-package-content-promo
                            className="text-5xl text-primary font-semibold"
                        >
                            {packagePrice}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default PurchasePackageContent;

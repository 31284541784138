//react
import React from "react";
import { PDFViewer } from "@react-pdf/renderer";
// i18n
// import i18n from "../../imports/i18n";
// import SplashScreen from "../../components/desktopComponent/splashScreen/splashScreen";

// Style
// import "./easterEgg.scss";
import "react-toastify/dist/ReactToastify.css";
import { NotarizeDocument } from "../../components";

export default function EasterEgg(props) {
  const transactionFields = {
    tx: {
      from: "roy",
      to: "gresp",
      transactionHash: "0x88",
      blockNumber: "521",

      blockHash: "0x533",
    },
    cellData: {
      name: "doc name",
    },
    zipHash: "file Hash",
    ipfsAddresses: {
      zip: "ipfsAddress",
    },
  };
  return (
    // <SplashScreen
    //   type={"pending"}
    //   message={i18n.t("messages.notarization_pending")}
    // />

    <PDFViewer style={{ width: "100%", height: "100%" }}>
      <NotarizeDocument notarization={transactionFields} />
    </PDFViewer>
  );
}

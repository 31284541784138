import React from "react";

// import "./purchasePackageTitle.scss";

const purchasePackageTitle = ({
    isOpen,
    title,
    packageDescription,
    packagePrice,
    ...props
}) => {
    return !isOpen ? (
        <div
            //purchase-package-closed
            className="w-full pl-2 pr-2 pt-2 pb-5 bg-primary text-secondary"
        >
            <div
                //title-purchase-package
                className="text-2xl font-medium"
            >
                {title}
            </div>
            <div
                //subtitle-purchase-package
                className="flex flex-row justify-between mt-2 text-xl font-regular"
            >
                <div
                    //description-purchase-package
                    className="leading-6"
                >
                    {packageDescription}
                </div>
                <div
                    //price-purchase-package
                    className="text-2xl leading-7 font-medium"
                >
                    {packagePrice}
                </div>
            </div>
        </div>
    ) : (
        <div
            //purchase-package-opened
            className="w-full pl-5 pr-2 pt-2 pb-2 bg-primary text-secondary rounded-t-xl"
        >
            <div
                //title-purchase-package
                className="text-2xl font-medium"
            >
                {title}
            </div>
        </div>
    );
};

export default purchasePackageTitle;

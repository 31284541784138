import React from "react";

// Style
// import "./squareIconButton.scss";

// Material UI
import Button from "@material-ui/core/Button";

const SquareIconButton = ({
  icon = null,
  isSelected = false,
  notification = false,
  onClick = null,
  ...props
}) => {
  const handleClick = event => {
    onClick && onClick();
  };

  return (
    <div
      id={`square-icon-button${isSelected ? "-active" : ""}`}
      className={`relative box-border w-16 h-16`}
    >
      <button
        onClick={handleClick}
        className={`w-16 h-16 flex flex-col justify-center items-center box-border rounded-10 ${
          isSelected ? "bg-primary" : "bg-grey"
        }`}
      >
        <div
          className={`p-1 flex justify-center items-center box-border ${
            !isSelected && "opacity-50"
          } `}
        >
          {icon}
        </div>
      </button>
      {notification && (
        <div
          className="absolute w-5 h-5 bg-teal-400"
          style={{
            top: "-10px",
            right: "-10px",
            borderRadius: "50%",
            boxShadow: "0px 4px 7px rgba(0, 0, 0, 0.25)",
          }}
        ></div>
      )}
    </div>
  );
};

export default SquareIconButton;

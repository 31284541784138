import React, { useState } from "react";

// Style
//import "./customTooltip.scss";

// Material UI
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

const CustomTooltip = ({ tooltipText = null, children = null, ...props }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(!open);
    };

    const handleHoverStart = () => {
        if (!open) setOpen(true);
    };

    const handleHoverFinish = () => {
        if (open) setOpen(false);
    };

    return !tooltipText || !children ? (
        <div>no tooltip or children component</div>
    ) : (
        <Tooltip
            title={tooltipText}
            placement="top"
            id={`customTooltip`}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            TransitionComponent={Zoom}
            TransitionProps={{ timeout: 300 }}
        >
            <div
                onMouseEnter={handleHoverStart}
                onMouseLeave={handleHoverFinish}
                onClick={handleOpen}
            >
                {children}
            </div>
        </Tooltip>
    );
};

export default CustomTooltip;

import { CONTRACTS } from "../../imports/constants";
import { sdk } from "../../imports/utils";

import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  DocumentReference,
} from "firebase/firestore";
import { db } from "../../redux-observables/firebase/firebase";

export async function getTokenData(tokenId, address, userName) {
  const foreverBambuNFT = sdk.getContract(address, CONTRACTS.FOREVER_BAMBU.abi);

  let owner = await foreverBambuNFT.ownerOf(tokenId);
  console.log(
    "OWNER ==>",
    owner,
    tokenId,
    sdk.getWalletAddress().toLowerCase(),
  );

  const q = query(
    collection(db, "users"),
    where("address", "==", owner.toLowerCase()),
  );
  const { docs } = await getDocs(q);

  const doc = docs[0]?.data();

  return doc?.username || owner;
}

export async function getUserByAddr(address) {
  const q = query(
    collection(db, "users"),
    where("address", "==", address.toLowerCase()),
  );
  const { docs } = await getDocs(q);

  return docs[0]?.data();
}

export async function transferNFTCall(contractAddress, tokenId, to, metadata) {
  try {
    const metaTx = await sdk.prepareTransaction(
      {
        address: contractAddress,
        abi: CONTRACTS.FOREVER_BAMBU.abi,
        name: "ForeverBambuNFT",
        version: "0.0.1",
      },
      "transferFrom",
      [sdk.getWalletAddress(), to, tokenId],
    );

    const requestId = await sdk.executeAsyncTransaction(metaTx, {
      webhookUrl: `${process.env.REACT_APP_FIREBASE_ENDPOINT}/saveTransferReceipt`,
      verbose: true,
      metadata,
    }); //optionals webhook address

    await updateDoc(doc(db, "nfts", metadata.nftId), {
      transfer: { underTransfer: true, requestId },
    });
  } catch (err) {
    console.log(err);
  }
}

import React from "react";

//components
import { CircleIconButton, Icons, Icon } from "../index";

//style
// import "./defaultCard.scss";
import { colors } from "../../imports/constants";
import i18n from "../../imports/i18n";

function DefaultCard({
  topIcon = null,
  onClick = null,
  optionsClick = null,
  title = "",
  info = [],
  isDisabled = false,
  status = false,
}) {
  return (
    <div
      className={`cursor-pointer rounded-20 w-full h-full p-1 flex max-w-450 justify-center items-center flex-col box-border border-lightGrey border border-solid bg-primaryGradientw`}
      style={{
        boxShadow: "0px 8px 20px -12px rgba(0, 0, 0, 0.25)",
      }}
      onClick={() => onClick && onClick()}
    >
      <div
        className={`w-full pl-2 pr-2 pt-2 flex justify-between items-center box-border  transition ease-in-out delay-150`}
      >
        <span
          className={`text-xl font-semibold text-primary
                    `}
        >
          {title}
        </span>
        {topIcon && <div className="icon-top-box">{topIcon}</div>}
      </div>
      <div
        className={`w-full text-xl flex justify-between items-center box-border pt-2 pl-0 pr-0 flex-col overflow-hidden text-black font-semibold transition ease-in-out delay-150`}
      >
        {/* <div
          className={`w-full pl-2 pr-2 flex items-center box-border pt-1 pb-1 text-base justify-between font-light text-black transition ease-in-out delay-150`}
        >
          {textOne && !subtitleOne ? (
            <span className="flex items-center w-full font-semibold">
              {textOne}
            </span>
          ) : (
            <div className="flex items-center w-full font-semibold">
              {subtitleOne && `${subtitleOne}:`}
              <div className="ml-1 font-light">{textOne}</div>
            </div>
          )}
        </div> */}

        <div
          className={`w-full pl-2 pr-2 flex-row  items-center box-border pt-1 pb-1 text-base justify-between font-light text-black transition ease-in-out delay-150`}
        >
          {info.map((title, i) => {
            return (
              title && (
                <div key={i} className="flex items-center w-full font-semibold">
                  {`${i18n.t(title.key)}: `}
                  <div className="ml-3 font-light">{title.value}</div>
                  {optionsClick && i === info.length - 1 && (
                    <CircleIconButton
                      size="small"
                      onClick={() => optionsClick()}
                      icon={
                        <Icon
                          name={Icons.OPTIONS}
                          fill={colors["black"]}
                          style={{
                            width: 15,
                            height: 15,
                          }}
                        />
                      }
                      disabled={!optionsClick}
                      styleType={"secondary"}
                      className={`bg-transparent ml-auto shadow-none`}
                    />
                  )}
                </div>
              )
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default DefaultCard;

//react
import React from "react";

//i18n
import i18n from "../../imports/i18n";

function AvatarImage({ image, imgBlob, style = {} }) {
  const imageUrl = image && URL.createObjectURL(image);

  return (
    <>
      {imgBlob || imageUrl ? (
        <img
          id="avatarImage"
          src={imgBlob || imageUrl}
          alt={i18n.t("loading")}
          style={{ ...style }}
          className={`w-36 h-36 box-border rounded-full flex justify-center items-center object-contain text-2xl bg-grey text-secondary font-semibold`}
        />
      ) : (
        <div id="avatarImage">{i18n.t("no_image")}</div>
      )}
    </>
  );
}

export default AvatarImage;

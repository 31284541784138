import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

import { QueryClient, QueryClientProvider } from "react-query";

import { AuthProvider } from "./redux-observables/firebase/context";

const queryClient = new QueryClient();

ReactDOM.render(
  <React.Fragment>
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </AuthProvider>
  </React.Fragment>,
  document.getElementById("root"),
);

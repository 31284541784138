//react
import React, { useState, useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";

//components
import {
  TopBar,
  DrawerMenu /* DrawerMenuDesktop */,
  SplashScreen,
} from "./components/index";

//config
import { routes } from "./imports/config";

import { sdk } from "./imports/utils";
import { toast, ToastContainer } from "react-toastify";

import i18n from "./imports/i18n";

import packageJson from "../package.json";
import { AuthContext } from "./redux-observables/firebase/context";
import { TOAST_CONFIG } from "./imports/constants";
import Lottie from "react-lottie-player";
import { SecondaryLoading } from "./imports/animations";

const CustomRoute = ({
  component: Component,
  authed,
  redirect,
  componentProps,
  route,
  logout,
  location,
  user,
  errors,
  onAlertClose,
  isLoading,
  ...getInitialProps
}) => {
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  function toggleDrawer(event) {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsOpen(!isOpen);
  }
  useEffect(() => {}, [errors]);

  // console.log(
  //   (!!user?.logged &&
  //     route?.logged &&
  //     (route?.role ? route?.role === user?.role : true)) ||
  //     (!user?.logged && !route?.logged) ||
  //     route?.unlogged,
  // );

  useEffect(() => {
    if (history.location.search) {
      const action = history.location.search.match(/\?mode=(.*?)&/)[1];
      const oobCode = history.location.search.match(/&oobCode=(.*?)&/)[1];
      history.push({ pathname: `/${action}`, state: { oobCode } });
    }
  }, [history.location]);

  return (
    <Route
      exact
      path={route.path}
      render={props =>
        isLoading ? (
          <div className="flex flex-row items-center justify-center h-screen overflow-hidden bg-fakeDesktopBlack">
            <div
              className={`w-full max-w-600 h-full relative box-border bg-white overflow-auto ${"w-full relative box-border bg-white"} `}
            >
              <SplashScreen type={"pending"} />
            </div>
          </div>
        ) : authed ? (
          <div className="flex flex-row items-center justify-center h-screen overflow-hidden bg-fakeDesktopBlack">
            {route.hasTopBar && (
              <TopBar
                admin={user.role === "admin"}
                onClickLeft={toggleDrawer}
                onClickRight={() => history.push(route.topBarFunction)}
                rightIcon={route.topBarIcon}
                goBack={route.goBack}
                title={
                  props.location?.state?.topBarLabel
                    ? props.location.state.topBarLabel
                    : route.topBarLabel
                }
                logo={route.hasLogo}
              />
            )}

            <>
              <div
                className={`w-full max-w-600 h-full relative box-border bg-white overflow-auto ${
                  route.hasTopBar
                    ? "overflow-y-auto px-30 pt-topBarHeight"
                    : route.onboarding
                    ? "w-full relative box-border bg-white"
                    : ""
                } `}
              >
                {route.hasDrawer && (
                  <DrawerMenu
                    isOpen={isOpen}
                    toggleDrawer={toggleDrawer}
                    logout={logout}
                    user={user}
                  />
                )}
                <Component {...{ ...props, ...componentProps }} />
              </div>
            </>

            <ToastContainer />
          </div>
        ) : (
          <Redirect to={redirect} />
        )
      }
    />
  );
};

export default function App(props) {
  const { user, signOut, isLoading } = useContext(AuthContext);

  useEffect(() => {
    window.scrollTo(0, 0);

    const effect = async () => {
      if (localStorage.getItem("version") !== packageJson.version) {
        localStorage.setItem("version", packageJson.version);
        signOut();
      }

      if (!user.needRecover) {
        if (user.logged) {
          if (!sdk.isInitialized()) {
            await sdk.init();
          }

          if (!sdk.getWalletAddress()) {
            sdk.setPrivateKey(user?.wallet?.privateKey);
          }
        }
      }
    };

    effect();
  }, [user]);

  return (
    <Router>
      <Switch>
        {routes.map((route, i) => {
          let componentProps = {};
          route.props?.map(prop => {
            return (componentProps[prop] = props[prop]);
          });
          return (
            <CustomRoute
              key={i}
              exact
              path={route.path}
              authed={
                (!!user?.logged &&
                  route?.logged &&
                  (route?.role ? route?.role === user?.role : true)) ||
                (!user?.logged && !route?.logged) ||
                route?.unlogged
              }
              redirect={route.redirect}
              component={route.component}
              componentProps={componentProps}
              route={route}
              logout={signOut}
              user={user}
              isLoading={isLoading}
            />
          );
        })}
      </Switch>
    </Router>
  );
}

export const theme = {
  primary: {
    base: `w-full mb-6 mt-2 flex flex-col relative`,
    variants: {
      default: "",
    },
  },
  secondary: {
    base: `relative w-full h-12 rounded-md bg-secondary shadow-primary border-0`,
    variants: {
      default: "",
    },
  },
};

export const text = {
  primary: {
    base: `mb-1 font-medium text-black`,
    variants: {
      default: "",
    },
  },
  secondary: {
    base: `pb-4 text-xl text-left text-black font-medium`,
    variants: {
      default: "",
    },
  },
};

import { Formik } from "formik";
import React, { useState, useEffect, useContext } from "react";
import { useQuery } from "react-query";
import { useAuthSendPasswordResetEmail } from "@react-query-firebase/auth";

import { useHistory } from "react-router-dom";
import { CustomInput, StyledButton } from "../../components";
import i18n from "../../imports/i18n";
import { AuthContext } from "../../redux-observables/firebase/context";
import { firstStepValidation, secondStepValidation } from "./FormType";

// import "./resetPassword.scss";

function ResetPassword(props) {
  const history = useHistory();

  const { resetPassword, sendNewPassword } = useContext(AuthContext);

  const [step, setStep] = useState(0);
  const [values, setValues] = useState();
  const [recoverId, setRecoverId] = useState(null);

  const { refetch } = useQuery(
    "passwordHandler",
    async () => {
      if (step === 0) {
        await resetPassword(values.email);
        setTimeout(() => history.push("/login"));
      } else {
        console.log(values);
        let passwordRequest = await sendNewPassword(values.password, recoverId);
        // console.log("password Request: ", passwordRequest);
        if (passwordRequest) {
          setTimeout(
            () =>
              history.push({
                pathname: "/login",
                state: {
                  toast: {
                    message: "reset_password.password_changed",
                    type: "success",
                  },
                },
              }),
            3000,
          );
        }
      }
    },
    { enabled: false },
  );

  // console.log("values: ", values);
  useEffect(() => values && refetch(), [values]);

  useEffect(() => {
    if (history.location.state?.oobCode) {
      setRecoverId(history.location.state.oobCode);
      setStep(1);
    }
  }, []);

  return (
    <div
      id={`resetPassword`}
      className={`w-full flex justify-center bg-secondary`}
      style={{ minHeight: "calc(100vh-80px" }}
    >
      <Formik
        initialValues={
          step === 0 ? { email: "" } : { password: "", checkPassword: "" }
        }
        validationSchema={
          step === 0 ? firstStepValidation : secondStepValidation
        }
        onSubmit={values => {
          console.log(values);
          setValues(values);
          // refetch();
        }}
      >
        {({
          handleChange,
          handleSubmit,
          values,
          errors,

          setFieldValue,
        }) => (
          <div
            //reset-password-container
            className="flex flex-col items-center justify-start w-full"
          >
            {step === 0 ? (
              <CustomInput
                type={"text"}
                label={i18n.t("reset_password.email")}
                value={values.email}
                placeholder={i18n.t("reset_password.email_placeholder")}
                onChange={e => setFieldValue("email", e)}
                errors={errors.email}
              />
            ) : (
              <>
                <CustomInput
                  type={"password"}
                  label={i18n.t("reset_password.password")}
                  value={values.password}
                  onChange={e => setFieldValue("password", e)}
                  errors={errors.password}
                />
                <CustomInput
                  type={"password"}
                  label={i18n.t("reset_password.check_password")}
                  value={values.checkPassword}
                  onChange={e => setFieldValue("checkPassword", e)}
                  errors={errors.checkPassword}
                />
              </>
            )}
            <StyledButton
              styleType="primary"
              onClick={handleSubmit}
              // isDisabled={
              //     !(values.email && !step) ||
              //     !(values.password === values.checkPassword)
              // }
            >
              {step === 0
                ? i18n.t("reset_password.ask_password_reset")
                : i18n.t("reset_password.save_new_password")}
            </StyledButton>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default ResetPassword;

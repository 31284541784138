//react
import React, { useState } from "react";
//components
import { StyledButton } from "../index";
import i18n from "../../imports/i18n";
import { Icons, Icon, CustomTooltip, CircleIconButton } from "../index";
import { colors, TOAST_CONFIG } from "../../imports/constants";
import { truncStringPortion } from "../../imports/utils";
import { toast } from "react-toastify";

//style
// import "./fileInput.scss";

const ProDataInput = ({
  isDisabled = true,
  rightIcon = null,
  className = "",
  label = "",
  textComponent = "",
  copy = false,
  onChange = null,
}) => {
  const [disabled, setDisabled] = useState(isDisabled);

  const handleClick = e => {
    //console.log(e);
    if (copy) {
      navigator.clipboard.writeText(textComponent);

      toast.success(
        i18n.t(`messages.copied`, { string: textComponent }),
        TOAST_CONFIG,
      );
    } else {
      setDisabled(!disabled);
    }
  };

  //   const handleSubmit = e => {
  //     e.preventDefault();

  //     // const formData = new FormData(document.getElementById(id));

  //     onChange();
  //   };

  return (
    <div
      className={`w-full flex flex-col justify-center items-center box-border mt-4 mb-4`}
    >
      <div
        //row-container
        className="flex flex-col items-center justify-center w-full h-full"
        style={{ paddingTop: "1%" }}
      >
        {label && (
          <div
            //label-container (pb-4 removed )

            className={`flex items-center justify-between w-full pb-4 `}
          >
            <div
              className={`text-lg text-left relative flex items-center text-primary font-medium`}
            >
              {label}
            </div>
          </div>
        )}
        <div
          //text-container
          className="box-border flex items-center justify-between w-full pb-2 pl-2 border-0 border-black"
        >
          <input
            className={`text-left relative flex items-center text-black outline-none font-normal text-sm break-all w-full ${
              disabled ? "bg-white" : "bg-transparentPrimary"
            }`}
            defaultValue={
              copy ? truncStringPortion(textComponent, 4, 10) : textComponent
            }
            disabled={disabled}
            onChange={onChange}
          />

          <div>
            {label !== "Password" && (
              <CircleIconButton
                onClick={handleClick}
                className="cursor-pointer"
                icon={
                  <Icon
                    name={copy ? Icons.COPY : Icons.PENCIL}
                    fill={colors.primary}
                    style={{
                      width: 15,
                      height: 15,
                    }}
                  />
                }
                styleType="secondary"
              />
            )}
          </div>
        </div>
      </div>
      <div className={`h-px w-full bg-grey`} />
    </div>
  );
};
export default ProDataInput;

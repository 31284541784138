//react
import React from "react";

//components
import { StyledButton } from "../index";

//style
// import "./fileInput.scss";

const FileInput = ({
  styleType = "primary",
  onChange = null,
  isDisabled = false,
  uploaded = false,
  children = "no children",
  leftIcon = null,
  rightIcon = null,
  className = "",
  acceptingFiles = "application/pdf, .jpg, .png, .json",
  id = "files",
}) => {
  const handleClick = () => {
    document.getElementById(id + "input").value = "";
    document.getElementById(id + "input").click();
  };

  const handleSubmit = e => {
    e.preventDefault();
    const formData = new FormData(document.getElementById(id));
    onChange(formData);
  };

  return (
    <form id={id}>
      <div
        id="fileInput"
        className={`relative flex items-center justify-center w-full`}
      >
        {/* <div
        //className="hidden"
        > */}
        <input
          id={id + "input"}
          type="file"
          accept={acceptingFiles}
          name="file"
          className="hidden"
          //onChange={e => handleChange(e.target)}
          onInput={handleSubmit}
          //onChange={handleSubmit}
        />
      </div>
      <StyledButton
        styleType={styleType}
        onClick={handleClick}
        isDisabled={isDisabled}
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        className={className}
      >
        {children}
      </StyledButton>
      {/* <button onClick={handleSubmit}>Submit</button> */}
      {/* </div> */}
    </form>
  );
};

export default FileInput;

import React from "react";

//components
// import QrReader from "react-qr-reader";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import { colors } from "../../imports/constants";

import { Icon, Icons } from "../index";

export default function QrcodeReader({ onScan, opened, onClose }) {
    return opened ? (
        <div
            className={`absolute top-0 max-w-600 left-0 w-screen h-full flex items-center justify-around flex-col`}
            style={{
                background: "rgba(0,0,0,0.8",
                zIndex: "99",
            }}
        >
            {/* <div className="fade" /> */}
            <div className="relative flex flex-col items-center justify-center rounded-20">
                {/* <CropFreeIcon
                    fill={colors.white}
                    style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        zIndex: 99,
                    }}
                /> */}
                <Icon
                    icons={Icons.CROSS}
                    fill={colors.black}
                    style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        zIndex: 999,
                    }}
                />

                {/* <QrReader
                    delay={500}
                    style={{
                        width: "80vw",
                        maxWidth: "560px",
                        borderRadius: "20px",
                        paddingTop: "20px",
                    }}
                    // onError={() =>
                    //     toast.error(i18n.t("errors.wrong_qrcode"), {
                    //         position: "top-center",
                    //         autoClose: 3000,
                    //         hideProgressBar: false,
                    //         closeOnClick: true,
                    //         pauseOnHover: false,
                    //         draggable: true,
                    //         progress: undefined,
                    //     })
                    // }
                    onScan={e => onScan(e)}
                    showViewFinder={false}
                /> */}
            </div>
            <HighlightOffIcon
                fontSize="large"
                style={{
                    fill: "red",
                    // marginTop: "70%",
                    width: "65px",
                    height: "65px",
                    zIndex: "11",
                }}
                onClick={onClose}
            />
        </div>
    ) : (
        <></>
    );
}

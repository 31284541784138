//react
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

//i18n
import i18n from "../../imports/i18n";

import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../imports/constants";
//formik
import { Formik } from "formik";

//config
import {
  changePasswordInitialValue,
  changePasswordValidation,
} from "../../imports/config";

//components
import {
  CustomInput,
  StyledButton,
  CustomModal,
  ProPicInput,
  Loader,
  ProDataInput,
  CustomSwitch,
} from "../../components/index";

import { getAuth } from "firebase/auth";

//style
// import "./profile.scss";
import { colors } from "../../imports/constants";
import { omit } from "lodash";
import { getBase64 } from "../../imports/utils";
import { useEnv } from "../../imports/hooks";
import { AuthContext } from "../../redux-observables/firebase/context";

function Profile(props) {
  const { changeLanguage, language, updateUserPhoto, loading, updateUserInfo } =
    props;

  const {
    user,
    uploadProPic,
    updateEmail,
    uploadUserData,
    signOut,
    updatePassword,
  } = useContext(AuthContext); //add func name
  const auth = getAuth();
  const history = useHistory();
  const { isProd } = useEnv();
  const [profileData, setProfileData] = useState({
    name: user.name,
    surname: user.surname,
    email: user.email,
    username: user.username,
    checks: {
      privacy: true,
      // marketing: user.checks.marketing,
      use_and_condition: user.checks.use_and_condition,
      newsletter: user.checks.newsletter,
    },
  });

  // console.log(user);
  // console.log(profileData.checks);

  const [showModal, handleModal] = useState(false);
  const [modalType, selectModal] = useState(null);
  // const [activeSwitch, setActiveSwitch] = useState(false);
  const [file, setFile] = useState(null);
  const [proPic, setProPic] = useState(null);

  useEffect(() => {
    if (file) {
      // console.log(file);
      uploadProPic(file);
    }
  }, [file]);

  const handleProChange = async () => {
    let uploadData = false;
    Object.keys(profileData).forEach(key => {
      if (profileData[key] !== user[key]) {
        // console.log(key, " changed");
        uploadData = true;
      }

      // const value = user[key];
      // console.log("some field changed");
    });
    if (uploadData) {
      // signOut();
      // CHECK: if user changed only the email, we should not call the db twice
      await uploadUserData(omit(profileData, ["email"]));

      if (profileData.email !== user.email) {
        await updateEmail(profileData.email);

        setTimeout(async () => {
          signOut();
        }, 3000);
      }
    } else
      toast.error(i18n.t("you didn't change any data field"), TOAST_CONFIG);
  };

  // console.log("user: ", user);
  const changePasswordPopupForms = [
    {
      label: i18n.t("profile.prev_password"),
      type: "password",
      placeholder: i18n.t("forms_placeholders.password"),
      formName: "previousPassword",
    },
    {
      label: i18n.t("profile.new_password"),
      type: "password",
      placeholder: i18n.t("forms_placeholders.password"),
      formName: "newPassword",
    },
    {
      label: i18n.t("profile.repeat_new_password"),
      type: "password",
      placeholder: i18n.t("forms_placeholders.password"),
      formName: "repeatPassword",
    },
  ];

  const openModal = () => {
    handleModal(true);
  };

  const closeModal = () => {
    handleModal(false);
  };

  const modalContent = type => {
    switch (type) {
      case "password":
        return (
          <div
            //password-modal-container (check justification)
            className="box-border flex flex-col items-center w-full h-full pt-2 pb-8 overflow-x-hidden xs:justify-start justify-evenly"
          >
            <div
              //popup=title
              className="pt-5 pb-8 text-xl font-semibold text-primary"
            >
              {i18n.t("profile.modify_password")}
            </div>

            <Formik
              initialValues={changePasswordInitialValue}
              validationSchema={changePasswordValidation}
              onSubmit={values => {
                console.log(values.newPassword);
                updatePassword(values.newPassword);
                closeModal();
                setTimeout(async () => {
                  signOut();
                }, 4000);
              }}
            >
              {({ handleChange, handleSubmit, values, errors }) => (
                <>
                  {changePasswordPopupForms.map((el, i) => (
                    <CustomInput
                      key={i}
                      type={el.type}
                      label={el.label}
                      value={values[el.formName]}
                      placeholder={el.placeholder || null}
                      onChange={handleChange(el.formName)}
                      errors={errors[el.formName]}
                    />
                  ))}
                  <div
                    //change-password-buttons
                    //FIX xs:min-h-[140px]
                    className="w-4/5 h-32 flex flex-col items-center justify-around xs:min-h-[140px]"
                    style={{ marginTop: "40px" }}
                  >
                    <StyledButton styleType="primary" onClick={handleSubmit}>
                      {i18n.t("profile.apply_changes")}
                    </StyledButton>
                    <StyledButton styleType="secondary" onClick={closeModal}>
                      {i18n.t("profile.cancel")}
                    </StyledButton>
                  </div>
                </>
              )}
            </Formik>
          </div>
        );

      // case "language":
      //   return (
      //     <div className="flex flex-col items-center w-full h-full pb-8">
      //       <div className="relative flex items-center justify-between w-full pt-5 pb-5 text-xl font-semibold text-primary">
      //         {/* <div
      //                           style={{ width: "40px", height: "40px" }}
      //                       ></div> */}
      //         {i18n.t("preferences.language_selector")}
      //         <CircleIconButton
      //           onClick={() => {
      //             closeModal();
      //           }}
      //           icon={
      //             <Icon
      //               name={Icons.CROSS}
      //               fill={colors.primary}
      //               style={{
      //                 width: 30,
      //                 height: 30,
      //               }}
      //             />
      //           }
      //           styleType={"secondary"}
      //         />
      //       </div>

      //       <StyledButton
      //         styleType={language === "it" ? "primary" : "secondary"}
      //         onClick={handleLanguageIT}
      //         leftIcon={
      //           language === "it" && (
      //             <Icon
      //               name={Icons.FAVORITESTAR}
      //               style={{
      //                 width: 20,
      //                 height: 20,
      //               }}
      //             />
      //           )
      //         }
      //       >
      //         {i18n.t("preferences.italian")}
      //       </StyledButton>
      //       <StyledButton
      //         styleType={language === "en" ? "primary" : "secondary"}
      //         onClick={handleLanguageEN}
      //         leftIcon={
      //           language === "en" && (
      //             <Icon
      //               name={Icons.FAVORITESTAR}
      //               style={{
      //                 width: 20,
      //                 height: 20,
      //               }}
      //             />
      //           )
      //         }
      //       >
      //         {i18n.t("preferences.english")}
      //       </StyledButton>
      //     </div>
      //   );

      default:
        return <div>no modal selected</div>;
    }
  };

  const userInfo = {
    private: [
      // { label: "name", key: "name" },
      // { label: "surname", key: "surname" },
      { label: "name", key: "name", top: true },
      { label: "surname", key: "surname", top: true },
      { label: "email", key: "email" },
      { label: "username", key: "username" },
      { label: "password", key: "password" },
      { label: "address", key: "wallet.address", tooltip: true, copy: true },
      {
        label: "privateKey",
        key: "wallet.privateKey",
        tooltip: true,
        copy: true,
      },
    ],
  };

  // useEffect(() => {
  //   console.log("private Changed: ", userInfo.private);
  // }, [userInfo]);

  return loading ? (
    <Loader />
  ) : (
    // <div className={`min-h-screen`}>
    <div>
      <CustomModal
        opened={showModal}
        onBackdropClick={closeModal}
        content={modalContent(modalType)}
      />

      <div
        //personal-info //FIX media screen
        className="flex"
      >
        <div
          //personal-info-data
          className="flex flex-col w-3/5"
        >
          {/* {Object.keys(profileData).map(key => { */}
          {userInfo["private"].map(({ key, top }) => {
            const value = profileData[key];
            return (
              top && (
                <ProDataInput
                  key={key}
                  styleType={"tertiary"}
                  label={i18n.t(`profile.${key}`)}
                  textComponent={value}
                  //bottomRightItem={key.bottomRightItem}
                  //textCut={key.textCut}
                  onChange={e => {
                    setProfileData({ ...profileData, [key]: e.target.value });
                  }}
                />
              )
            );
          })}
        </div>
        <div
          //photo-container
          className="flex-1 ml-8"
        >
          <>
            <ProPicInput
              className="box-border relative px-3 py-4 text-black bg-center bg-no-repeat shadow-none w-44 h-44 bg-upload"
              styleType="avatarUpload"
              onChange={imageObj => {
                if (imageObj) {
                  setFile(imageObj);
                  getBase64(imageObj).then(res => setProPic(res));
                }
              }}
            >
              {file || user.propic ? (
                // <AvatarImage imgBlob={file.blob || user.profilePhoto} />
                <img
                  src={proPic || user.propic}
                  alt={i18n.t("loading")}
                  className={`w-full h-full bg-white`}
                />
              ) : (
                ""
              )}
            </ProPicInput>
          </>
        </div>
      </div>
      <div style={{ marginTop: "-15px" }}>
        {userInfo["private"].map(({ key, tooltip, label, top, copy }) => {
          const split = key.split(".");
          // const value = profileData[key];
          const value =
            split.length > 1
              ? user[split[0]][split[1]]
              : profileData[key] || "********";

          return (
            !top && (
              <ProDataInput
                key={key}
                styleType={"tertiary"}
                label={i18n.t(`profile.${label}`)}
                textComponent={value}
                // copy={enabledCopy[split[1] || key] ? split[1] || key : ""}
                copy={copy}
                tooltipText={tooltip ? i18n.t(`profile.${key}_tooltip`) : ""}
                onChange={e => {
                  setProfileData({ ...profileData, [key]: e.target.value });
                }}
              />
            )
          );
        })}
        {Object.keys(profileData.checks).map(key => {
          if (key !== "privacy") {
            return (
              <div
                className="flex flex-row items-center justify-between"
                key={key}
              >
                <label>{i18n.t("forms." + key + "_label")}</label>
                <CustomSwitch
                  // relatedSwitch={
                  //     el.relatedSwitch ? activeSwitch : true
                  // }
                  startingValue={profileData.checks[key]}
                  onFunction={() =>
                    setProfileData({
                      ...profileData,
                      checks: { ...profileData.checks, [key]: true },
                    })
                  }
                  offFunction={() =>
                    setProfileData({
                      ...profileData,
                      checks: { ...profileData.checks, [key]: false },
                    })
                  }
                  // disabled={el.disabled || false}
                />
              </div>
            );
          } else {
            return <></>;
          }
        })}
      </div>
      <StyledButton onClick={handleProChange}>
        {i18n.t("profile.update_profile")}
      </StyledButton>
      <StyledButton
        onClick={() => {
          selectModal("password");
          openModal();
        }}
      >
        {i18n.t("profile.change_password")}
      </StyledButton>
      <div className="flex flex-col items-center my-4">
        <a
          className="mb-3 text-primary"
          href="mailto:blockchain@foreverbambu.com?subject=Problemi App Forever Bambu'"
        >
          {i18n.t("profile.support")}
        </a>
        <div>
          <a
            className="mb-3 text-primary"
            target={"_blank"}
            rel={"noreferrer"}
            href="https://firebasestorage.googleapis.com/v0/b/forever-bambu-dev.appspot.com/o/Informativa_forever_bambu.pdf?alt=media&token=4c97ab81-20b0-4ae2-9827-f01716f75fd3"
          >
            {i18n.t("forms.privacy_label")}
          </a>
          {/* {" | "}
          <a className="mb-3 text-primary" href="#">
            {i18n.t("forms.issues")}
          </a> */}
        </div>
      </div>
    </div>
  );
}

export default Profile;
